/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-case-declarations */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-fallthrough */
import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import {
  Input,
  SelectSearch,
  DatePicker,
  TaskDetail,
  TransactionType,
  ErrorHandling,
  PulseOpsFormat,
  AppContext,
  AuthService,
  Toast,
  GeneralService,
  assets,
  TaskDetailService
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { LeaderCodeModal, Panel } from '../component'
import { Controller, UseFormReturn, useWatch } from 'react-hook-form'
import moment from 'moment'
import { GeneralForm } from './da-genneral-form'
import { GeneralInformationSelect } from '../mock'
import { ContactAddress } from './da-contact-address'
import { AllocatedAddress } from './da-allocated-address'
import { ResidentialAddress } from './da-residential-address'
import { BankAccount } from './da-bank-account'
import { BankAccountOther } from './da-bank-account-other'
import _ from 'lodash'
import { DaGeneralInfoService } from './da-genneral-info-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import {
  applicationInfo,
  bankAccountInfo,
  candidateInfo,
  contactInfo,
  educationInfo,
  maritalStatus,
  otherInfo
} from './constants'
import { useLoading } from '@mxt/zio-react'
import { Footer } from '../da-request-info'
import { DAContext } from '..'
import { Color } from '@material-ui/lab'
type Props = {
  detail: TaskDetail.AgentOnboardNew
  form: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'>
  isHistory: boolean | null
  setStatusSave: (val: boolean) => void
  checkSubTabIndex: (val: number) => void
  setIsScroll?: (isCheck: boolean) => void
  isScroll?: boolean
}

const enum SalesType {
  AGENCY = 'AGENCY',
  GALLERIE = 'GALLERIE',
  MILLENNIAL = 'MILLENNIAL',
  PTD = 'PTD',
  DSR = 'DSR'
}
const enum SubChannel {
  FSC = 'FSC',
  TMR = 'TMR',
  QAS = 'QAS'
}

const setChannelLinkByPRUforce = (detail: TaskDetail.AgentOnboardNew, terReason: string): string => {
  switch (detail.transactionType) {
    case TransactionType.AGENT_ONBOARD_NEW:
      if (_.isNil(detail.agentCode)) {
        if(detail.salesType === SalesType.GALLERIE){
          return '2.4'
        }else {
          return '2.1'
        }
      }
    case TransactionType.AGENT_ONBOARD_REINS:
      const checkTerDate = moment().diff(moment(detail.terdate, 'DD/MM/YYYY'), 'month', true) >= 6
      const dummyUnit = ''
      if (checkTerDate || terReason !== 'ADPER' || detail.oldUnit === detail.newUnit || detail.oldUnit === dummyUnit) {
        if(detail.salesType === SalesType.GALLERIE){
          return '2.5'
        } else {
          return '2.2'
        }
      } else {
        if(detail.salesType === SalesType.GALLERIE){
          return '2.6'
        }else {
          return '2.3'
        }
      }
    default:
      return '-'
  }
}

const setChannelLinkByPRUdaily = (detail: TaskDetail.AgentOnboardNew, terReason: string): string => {
  const checkTerDate = moment().diff(moment(detail.terdate, 'DD/MM/YYYY'), 'month', true)
  const dummyUnit = ''
  switch (detail.salesType) {
    case SalesType.AGENCY:
      if (_.isNil(detail.agentCode)) {
        return '1.1'
      } else {
        if (
          checkTerDate >= 6 ||
          terReason !== 'ADPER' ||
          detail.oldUnit === detail.newUnit ||
          detail.oldUnit === dummyUnit
        ) {
          return '1.2'
        } else {
          return '1.3'
        }
      }
    case SalesType.GALLERIE:
      if (_.isNil(detail.agentCode)) {
        return '1.4'
      } else {
        if (checkTerDate >= 12) {
          return '1.5'
        } else {
          return '1.6'
        }
      }
    case SalesType.MILLENNIAL:
      if (_.isNil(detail.agentCode)) {
        return '1.7'
      } else {
        if (
          checkTerDate >= 6 ||
          terReason !== 'ADPER' ||
          detail.oldUnit === detail.newUnit ||
          detail.oldUnit === dummyUnit
        ) {
          return '1.8'
        } else {
          return '1.9'
        }
      }
    case SalesType.PTD:
      if (_.isNil(detail.agentCode)) {
        return '1.10'
      } else {
        if (checkTerDate < 3) {
          return '1.11'
        } else {
          return '1.12'
        }
      }
    case SalesType.DSR:
      switch (detail.subChanel) {
        case SubChannel.FSC:
          if (_.isNil(detail.agentCode)) {
            return '1.13'
          } else {
            if (checkTerDate < 3) {
              return '1.14'
            } else {
              return '1.15'
            }
          }
        case SubChannel.TMR:
          if (_.isNil(detail.agentCode)) {
            return '1.16'
          } else {
            if (checkTerDate < 3) {
              return '1.17'
            } else {
              return '1.18'
            }
          }
        case SubChannel.QAS:
          if (_.isNil(detail.agentCode)) {
            return '1.19'
          } else {
            if (checkTerDate < 3) {
              return '1.20'
            } else {
              return '1.21'
            }
          }
        default:
          return '-'
      }
    default:
      return '-'
  }
}
export const GeneralInfoScreen = React.memo((props: Props) => {
  const { t, i18n } = useTranslation('RequestInfo')
  const { detail, form } = props
  const { control, setValue, getValues, reset } = form
  const [loading, bindLoader] = useLoading(false)
  const newDate = moment().valueOf()
  const [terReason, setTerReason] = React.useState('')
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const { point } = React.useContext(DAContext.DAGeneralContextInstance)
  const [validateField, setValidateField] = React.useState<boolean>(false)
  const [comment, setComment] = React.useState<string>('')
  const [nameComment, setNameComment] = React.useState<string>('')
  const [requiredLeaderCode, setRequiredLeaderCode] = React.useState<string>('')
  const [agentCodeRefer, setAgentCodeRefer] = React.useState<string>('')
  const [checkLeaderCode, setCheckLeaderCode] = React.useState<boolean>(false)
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const occupations =
    pipe(
      GeneralService.getOccupations,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []
  const countries =
    pipe(
      GeneralService.getCountries,
      ZIO.map((r) =>
        r.map((item) => ({
          value: item.code,
          label: item.name
        }))
      ),
      ErrorHandling.runDidUpdate([i18n.language])
    ) || []

  React.useEffect(() => {
    form.setValue('checkDuplicate', form.getValues('checkDuplicate'))
    if (countries && countries.length > 0) {
      const nationItem =
        countries.find(
          (item) => item.value === getValues('nationality.value') || item.value === props.detail.nationality
        ) ?? null
      form.setValue('nationality', nationItem)
    }
    if (occupations && occupations.length > 0) {
      const occupationItem =
        occupations.find(
          (item) => item.value === getValues('Occupation.value') || item.value === props.detail.occupationCode
        ) ?? null
      form.setValue('Occupation', occupationItem)
    }
  }, [countries, occupations])
  pipe(
    DaGeneralInfoService.getTerminarReason(
      detail.agentCode || '',
      detail.terdate ? PulseOpsFormat.datetoFormat(moment(detail.terdate, 'DD/MM/YYYY').toDate(), 'yyyyMMDD') : ''
    ),
    ZIO.map((data) => {
      //moment
      !_.isEmpty(data) && setTerReason(data[0])
    }),
    ErrorHandling.runDidMount([])
  )

  const agentGeneralInfo = pipe(
    DaGeneralInfoService.getGeneralInfoLog(props.detail.businessKey ?? ''),
    ZIO.map((res) => res?.data),
    ZIO.tap((data) => {
      return ZIO.unit
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  )

  React.useEffect(() => {
    if (agentGeneralInfo) {
      // const qsCoreInfo = (agentGeneralInfo.qsCoreInfo ? JSON.parse(agentGeneralInfo.qsCoreInfo) : '') as qsCoreInfo
      const applicationInfo = (
        agentGeneralInfo?.applicationInfo ? JSON.parse(agentGeneralInfo?.applicationInfo) : ''
      ) as applicationInfo
      const conTact = (agentGeneralInfo?.contactInfo ? JSON.parse(agentGeneralInfo?.contactInfo) : '') as contactInfo
      const canDiDate = (
        agentGeneralInfo?.candidateInfo ? JSON.parse(agentGeneralInfo?.candidateInfo) : ''
      ) as candidateInfo
      const otherInfomation = (agentGeneralInfo.otherInfo ? JSON.parse(agentGeneralInfo?.otherInfo) : '') as otherInfo
      const bankAccountInfo = (
        agentGeneralInfo?.bankAccountInfo ? JSON.parse(agentGeneralInfo?.bankAccountInfo) : ''
      ) as bankAccountInfo
      const educationInfo = (
        agentGeneralInfo?.educationInfo ? JSON.parse(agentGeneralInfo?.educationInfo) : ''
      ) as educationInfo
      const maritalStatus = (
        agentGeneralInfo?.maritalStatus ? JSON.parse(agentGeneralInfo?.maritalStatus) : ''
      ) as maritalStatus
      const asSessment = agentGeneralInfo?.assessment
      !!agentGeneralInfo &&
        agentGeneralInfo &&
        reset({
          ...getValues(),
          subChannel: applicationInfo.subChanel,
          eREF: applicationInfo.eREF,
          salesType: applicationInfo.salesType,
          channelLink: applicationInfo.ChannelLink,
          status: asSessment?.status,
          assessmentDate: asSessment?.assessmentDate,
          role: asSessment?.role,
          assessByDesc: asSessment?.assessByDesc,
          reAssess: asSessment?.reAssess,
          reAssessDate: asSessment?.reAssessDate,
          agCode: applicationInfo.agCodeReinAgent,
          leaderCode: !!applicationInfo.leaderCode ? applicationInfo.leaderCode : null,
          leadercodedescription: applicationInfo.leaderCodeDescription,
          officecode: applicationInfo.officeCode,
          saleZone: applicationInfo.saleZone,
          regioncode: applicationInfo.regionCode,
          regiondescription: applicationInfo.regionDescription,
          oldidNo: canDiDate.oldIDNo,
          // fullName:
          //   !!canDiDate.lastName && !!canDiDate.firstName ? canDiDate.lastName + ' ' + canDiDate.firstName : null,
          lastName: !!canDiDate.lastName ? canDiDate.lastName : null,
          firstName: !!canDiDate.firstName ? canDiDate.firstName : null,
          // dateofBirth: PulseOpsFormat.datetoFormat(canDiDate.dateOfBirth, 'YYYY/MM/DD'),
          dateofBirth: !!canDiDate.dateOfBirth
            ? moment(moment(canDiDate.dateOfBirth, 'DD/MM/YYYY').format('MM-DD-YYYY')).toDate()
            : null,
          //  new Date(canDiDate.dateOfBirth),
          genDer:
            !!canDiDate.gender && canDiDate.gender.value
              ? GeneralInformationSelect.getGenderOption(canDiDate.gender.value)(i18n.language)
              : null,
          checkDuplicate: canDiDate.checkDuplicate,
          nationality:
            !!otherInfomation.nationality && !!otherInfomation.nationality.value ? otherInfomation.nationality : null,
          ethNic: otherInfomation.ethNic,
          placeofBirth: !!otherInfomation.placeOfBirth ? otherInfomation.placeOfBirth : null,
          idDate: !!otherInfomation.idDate
            ? moment(moment(otherInfomation.idDate, 'DD/MM/YYYY').format('MM-DD-YYYY')).toDate()
            : null,
          idExpireddate: moment(moment(otherInfomation.idExpiredDate, 'DD/MM/YYYY').format('MM-DD-YYYY')).toDate(),
          //  new Date(otherInfomation.idExpiredDate),
          idPlace: otherInfomation.idPlace,
          agentcodeRefer: otherInfomation.agentCodeRefer,
          agentnameRefer: otherInfomation.agentNameRefer,
          phonenumber: !!conTact.phoneNumber ? conTact.phoneNumber : null,
          companyphone: conTact.companyPhone,
          HomePhone: conTact.homePhone,
          Email: !!conTact.email ? conTact.email : null,
          Zalo: conTact.zalo,
          ProvinceAllocated: conTact.provinceAllocated,
          DistrictAllocated: conTact.districtAllocated,
          WardAllocated: conTact.wardAllocated,
          StreetAllocated: conTact.streetAllocated,
          AllocatedlocationCode: conTact.locationCodeAllocated,
          ProvinceResidential:
            !!conTact.provinceResidential && !!conTact.provinceResidential.value ? conTact.provinceResidential : null,
          DistrictResidential:
            !!conTact.districtResidential && !!conTact.districtResidential.value ? conTact.districtResidential : null,
          WardResidential: !!conTact.wardResidential && conTact.wardResidential.value ? conTact.wardResidential : null,
          StreetResidential: !!conTact.streetResidential ? conTact.streetResidential : null,
          locationCodeResidential: conTact.locationCodeResidential,
          ProvinceContact:
            !!conTact.provinceContact && !!conTact.provinceContact.value ? conTact.provinceContact : null,
          DistrictContact:
            !!conTact.districtContact && !!conTact.districtContact.value ? conTact.districtContact : null,
          WardContact: !!conTact.wardContact && !!conTact.wardContact.value ? conTact.wardContact : null,
          StreetContact: !!conTact.streetContact ? conTact.streetContact : null,
          locationCodeContact: conTact.locationCodeContact,
          BankName: !!bankAccountInfo.bankName && !!bankAccountInfo.bankName.value ? bankAccountInfo.bankName : null,
          Bankbranchname:
            !!bankAccountInfo.bankBranchName && !!bankAccountInfo.bankBranchName.value
              ? bankAccountInfo.bankBranchName
              : null,
          Bankcode: bankAccountInfo.bankCode,
          Bankaccount: !!bankAccountInfo.bankAccount ? bankAccountInfo.bankAccount : null,
          BanknameOther: bankAccountInfo.bankNameOther,
          BankbranchnameOther: bankAccountInfo.bankBranchNameOther,
          Education:
            !!educationInfo.education && !!educationInfo.education.value
              ? GeneralInformationSelect.getEducationOption(educationInfo.education.value)(i18n.language)
              : null,
          Specialization: educationInfo.specialization,
          Occupation: !!educationInfo.occupation && !!educationInfo.occupation.value ? educationInfo.occupation : null,
          Income:
            !!educationInfo.income && !!educationInfo.income.value
              ? GeneralInformationSelect.getIncomeOption(educationInfo.income.value)(i18n.language)
              : null,
          Taxcode: educationInfo.taxCode,
          // Maritalstatus: maritalStatus.maritalStatus,
          Maritalstatus:
            !!maritalStatus.maritalStatus && maritalStatus.maritalStatus.value
              ? GeneralInformationSelect.getMaritalOption(maritalStatus.maritalStatus.value)(i18n.language)
              : null,
          Numberofchildren: maritalStatus.numberOfChildren
        })
      //!!res && res.data && props.onDefaultValueForm?.(res?.data)
    }
  }, [agentGeneralInfo])

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const checkDuplicate = () => {
    return pipe(
      DaGeneralInfoService.getcheckDuplicateFullNameAndGenderAndDob({
        phone: getValues('phonenumber'), //require
        idNumber: props.detail.idNo, // require , /
        oldIdNumber: getValues('oldidNo'),
        fullName: getValues('lastName')?.toUpperCase() + ' ' + getValues('firstName')?.toUpperCase(),
        sex: getValues('genDer.value'),
        dateOfBirth: !!PulseOpsFormat.datetoFormat(getValues('dateofBirth'), 'yyyyMMDD')
          ? PulseOpsFormat.datetoFormat(getValues('dateofBirth'), 'yyyyMMDD')
          : PulseOpsFormat.datetoFormat(props.detail.dob, 'yyyyMMDD'),
        bankKey: getValues('BankName.value') + '-' + getValues('Bankbranchname.value'),
        bankAccKey: getValues('Bankaccount')
      }),

      ZIO.map((res): any => {
        setValue('checkDuplicate', true)
        if (res.data && res.data === 'Passed') {
          setValue('checkBank', false)
          setValue('checkPhone', false)
          return false
        } else if (res.responseStatus?.code === '248') {
          setValue('checkBank', true)
          setValue('checkPhone', true)
          showToast(t('DuplicateBankPhone'), 'error')
        } else if (res.responseStatus?.code === '247') {
          setValue('checkPhone', false)
          setValue('checkBank', true)
          showToast(t('message:MS160008'), 'error')
        } else if (res.responseStatus?.code === '246') {
          setValue('checkBank', false)
          setValue('checkPhone', false)
          // "Failed to Check Duplicate full name, Gender and Date of Birth!"
          showToast(t('message:MS160012'), 'error')
          return false
        } else if (res.responseStatus?.code === '245') {
          // "Check Phone Failed"
          setValue('checkBank', false)
          setValue('checkPhone', true)
          showToast(t('message:MS160007'), 'error')
        }
        return true
      }),
      ZIO.catchAll((_error) => {
        return ZIO.succeed(true)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }
  const checkAgentActive = () => {
    return pipe(
      DaGeneralInfoService.getcheckDuplicateActive({
        phone: getValues('phonenumber'), //require
        idNumber: props.detail.idNo, // require ,
        oldIdNumber: getValues('oldidNo'),
        fullName: getValues('lastName')?.toUpperCase() + ' ' + getValues('firstName')?.toUpperCase(),
        sex: getValues('genDer.value'),
        dateOfBirth: !!PulseOpsFormat.datetoFormat(getValues('dateofBirth'), 'yyyyMMDD')
          ? PulseOpsFormat.datetoFormat(getValues('dateofBirth'), 'yyyyMMDD')
          : PulseOpsFormat.datetoFormat(props.detail.dob, 'yyyyMMDD'),
        bankKey: getValues('BankName.value') + '-' + getValues('Bankbranchname.value'),
        bankAccKey: getValues('Bankaccount')
      }),

      ZIO.map((res): any => {
        if (res.responseStatus?.code === '249') {
          setValue('checkActiveAgent', false)
          showToast(t('DummyAgent'), 'error')

        } else if (res.responseStatus?.code === '250') {
          // "Failed to TerminatedAgent"
          setValue('checkActiveAgent', false)
          showToast(t('TerminatedAgent'), 'error')
        } else if (res.responseStatus?.code === '251') {
          // "Check ActiveAgent Failed"
          setValue('checkActiveAgent', true)
          showToast(t('ActiveAgent'), 'error')
          return false
        }
        return true
      }),
      ZIO.catchAll((_error) => {
        return ZIO.succeed(true)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }
  const provinces =
    pipe(
      DaGeneralInfoService.getProvinces,
      ZIO.map((res) => {
        return res
      }),
      ErrorHandling.runDidMount()
    ) || []
  const setSubChannel = () => {
    switch (props.detail.salesType) {
      case 'PTD':
        if (props.detail.subChanel === 'FSC' && props.detail.agCAT !== 'QAS') {
          return 'FSC'
        } else if (props.detail.subChanel === 'TMR') {
          return 'TMR'
        } else if (props.detail.subChanel === 'FSC' && props.detail.agCAT === 'QAS') {
          return 'QAS'
        }
      case 'DSR':
        if (props.detail.subChanel === 'FSC' && props.detail.agCAT !== 'QAS') {
          return 'FSC'
        } else if (props.detail.subChanel === 'TMR') {
          return 'TMR'
        } else if (props.detail.subChanel === 'FSC' && props.detail.agCAT === 'QAS') {
          return 'QAS'
        } else if (props.detail.subChanel === 'QAS' && props.detail.agCAT === 'QAS') {
          return 'QAS'
        }
      default:
        return '-'
    }
  }
  const checkDup = useWatch({ control, name: 'checkDuplicate' })
  const onReset = () => {
    reset(initData as any)
  }
  const initData = React.useMemo(() => {
    return {}
  }, [agentGeneralInfo])

  const onSave = async () => {
    const isValid = await form.trigger()
    if (checkLeaderCode === true) {
      return null
    }
    if (isValid && !(await checkDuplicate()) && (await checkAgentActive())) {
      form.setValue('isSaveGeral', true)
      const GeneralFormData = form.getValues() as GeneralForm.Raw
      const qsCoreInfo = { point }
      const application = {
        subChanel: GeneralFormData.subChannel,
        salesType: GeneralFormData.salesType,
        eREF: GeneralFormData.eREF,
        ChannelLink: GeneralFormData.channelLink,
        submissionDate: moment(props?.detail?.createdDate).format('DD/MM/yyyy'),
        assessmentstatus: GeneralFormData.status,
        assessmentdate: GeneralFormData.assessmentDate,
        assessbyrole: GeneralFormData.role,
        assessbydesc: GeneralFormData.assessByDesc,
        reassess: GeneralFormData.reAssess,
        reassessDate: GeneralFormData.reAssessDate,
        agCodeReinAgent: GeneralFormData.agCode,
        ocr: props.detail.ocr,
        leaderCode: GeneralFormData.leaderCode,
        leaderCodeDescription: GeneralFormData.leadercodedescription,
        saleZone: GeneralFormData.saleZone,
        officeCode: GeneralFormData.officecode,
        regionCode: GeneralFormData.regioncode,
        regionDescription: GeneralFormData.regiondescription,
        oldUnit: props.detail.oldUnit,
        terminatedDate: props.detail.terdate,
        termiNationReason: ''
      }
      // const fullNameArr = (GeneralFormData.fullName?.trim() ?? '').split(' ')
      // const getFirstname = [...fullNameArr]
      // const getLastname = fullNameArr.slice(0, fullNameArr.length - 1).join(' ')
      const candidateInfo = {
        idNo: props.detail.idNo,
        oldIDNo: GeneralFormData.oldidNo,
        // firstName: getFirstname.reverse()?.[0] ?? ' ',
        // lastName: fullNameArr.length > 0 ? getLastname : ' ',
        firstName: GeneralFormData.firstName,
        lastName: GeneralFormData.lastName,
        dateOfBirth: PulseOpsFormat.datetoFormat(GeneralFormData.dateofBirth, 'DD/MM/YYYY'),
        gender: GeneralFormData.genDer,
        checkDuplicate: GeneralFormData.checkDuplicate
      }
      const otherInfo = {
        nationality: GeneralFormData.nationality,
        ethNic: GeneralFormData.ethNic,
        placeOfBirth: GeneralFormData.placeofBirth,
        idDate: PulseOpsFormat.datetoFormat(GeneralFormData.idDate, 'DD/MM/YYYY'),
        idPlace: GeneralFormData.idPlace,
        idExpiredDate: PulseOpsFormat.datetoFormat(GeneralFormData.idExpireddate, 'DD/MM/YYYY'),
        agentCodeRefer: GeneralFormData.agentcodeRefer,
        agentNameRefer: GeneralFormData.agentnameRefer,
        note: '',
        status: GeneralFormData.isSaveGeral,
        major: '',
        potentialCustomer: props.detail.hasAListOfPotentialCustomers,
        agCAT: props.detail.agCAT
      }
      const contactInfo = {
        phoneNumber: GeneralFormData.phonenumber,
        companyPhone: GeneralFormData.companyphone,
        homePhone: GeneralFormData.HomePhone,
        email: GeneralFormData.Email,
        zalo: GeneralFormData.Zalo,
        provinceAllocated:
          GeneralFormData.ProvinceAllocated === null ? { label: '', value: '' } : GeneralFormData.ProvinceAllocated,
        districtAllocated: GeneralFormData.DistrictAllocated,
        wardAllocated: GeneralFormData.WardAllocated,
        streetAllocated: GeneralFormData.StreetAllocated,
        locationCodeAllocated: GeneralFormData.AllocatedlocationCode,
        provinceResidential: GeneralFormData.ProvinceResidential,
        districtResidential: GeneralFormData.DistrictResidential,
        wardResidential: GeneralFormData.WardResidential,
        streetResidential: GeneralFormData.StreetResidential,
        locationCodeResidential: GeneralFormData.locationCodeResidential,
        provinceContact: GeneralFormData.ProvinceContact,
        districtContact: GeneralFormData.DistrictContact,
        wardContact: GeneralFormData.WardContact,
        streetContact: GeneralFormData.StreetContact,
        locationCodeContact: GeneralFormData.locationCodeContact
      }
      const bankAccountInfo = {
        bankName: GeneralFormData.BankName,
        bankBranchName: GeneralFormData.Bankbranchname,
        bankCode: GeneralFormData.Bankcode,
        bankAccount: GeneralFormData.Bankaccount,
        bankNameOther: GeneralFormData.BanknameOther,
        bankBranchNameOther: GeneralFormData.BankbranchnameOther
      }
      const educationInfo = {
        education: GeneralFormData.Education,
        specialization: GeneralFormData.Specialization,
        occupation: GeneralFormData.Occupation,
        income: GeneralFormData.Income,
        taxCode: GeneralFormData.Taxcode
      }
      const maritalStatus = {
        maritalStatus: GeneralFormData.Maritalstatus,
        numberOfChildren: GeneralFormData.Numberofchildren
      }
      const asSessment = {
        status: GeneralFormData.status,
        assessmentDate: GeneralFormData.assessmentDate,
        role: GeneralFormData.role,
        assessByDesc: GeneralFormData.assessByDesc,
        reAssess: GeneralFormData.reAssess,
        reAssessDate: GeneralFormData.reAssessDate
      }
      const bodyPayload = {
        access_token: props.detail.access_token,
        action: props.detail.action,
        additionalProperties: props.detail.additionalProperties,
        agentCode: props.detail.agentCodes,
        authenOption: props.detail.authenOption,
        authenRequestForm: props.detail.authenRequestForm,
        body: {
          officeCode: GeneralFormData.officecode,
          terDate: props.detail.terdate,
          oldUnit: props.detail.oldUnit,
          newUnit: props.detail.newUnit,
          eREF: props.detail.eREF,
          subChanel: GeneralFormData.subChannel,
          salesType: GeneralFormData.salesType,
          /// SubmissionDate
          agCAT: props.detail.agCAT,
          agent: {
            agCategory: props.detail.agCategory,
            agentCode: props.detail.agentCode,
            agentCodeRefer: GeneralFormData.agentcodeRefer,
            agentNameRefer: GeneralFormData.agentnameRefer,
            companyPhone: GeneralFormData.companyphone,
            educationCode: GeneralFormData.Education?.value,
            email: GeneralFormData.Email,
            ethnic: GeneralFormData.ethNic,
            genderCode: GeneralFormData.genDer?.value,
            hasAListOfPotentialCustomers: props.detail.hasAListOfPotentialCustomers,
            homePhone: GeneralFormData.HomePhone,
            income: GeneralFormData.Income?.value,
            locationCode: GeneralFormData.locationCodeResidential,
            marriedCode: GeneralFormData.Maritalstatus?.value,
            numberOfChildren: GeneralFormData.Numberofchildren,
            occupationCode: GeneralFormData.Occupation?.value,
            ocr: props.detail.ocr,
            phoneNumber: GeneralFormData.phonenumber,
            regionCode: GeneralFormData.regioncode,
            regionDescription: GeneralFormData.regiondescription,
            saleZone: GeneralFormData.saleZone,
            specialization: GeneralFormData.Specialization,
            zalo: GeneralFormData.Zalo
          },
          assessment: {
            assessmentDate: props.detail.asessmentDate,
            assessByDesc: props.detail.assessByDesc,
            reAssess: props.detail.reAssess,
            reAssessDate: props.detail.reAssessDate,
            role: props.detail.role,
            status: props.detail.status
          },
          group: {
            leaderCode: GeneralFormData.leaderCode,
            leaderCodeDescription: GeneralFormData.leadercodedescription,
            leaderName: props.detail.leaderName,
            newLeaderCode: props.detail.newLeaderCode,
            newLeaderName: props.detail.newLeaderName,
            oldLeaderCode: props.detail.oldLeaderCode,
            oldLeaderName: props.detail.oldLeaderName
          },
          candidate: {
            idNo: props.detail.idNo,
            oldIdNo: GeneralFormData.oldidNo,
            idDate: PulseOpsFormat.datetoFormat(GeneralFormData.idDate, 'DD/MM/YYYY'),
            idPlace: GeneralFormData.idPlace,
            idExpiredDate: PulseOpsFormat.datetoFormat(GeneralFormData.idExpireddate, 'DD/MM/YYYY'),
            dob: PulseOpsFormat.datetoFormat(GeneralFormData.dateofBirth, 'DD/MM/YYYY'),
            placeOfBirth: GeneralFormData.placeofBirth,
            nationality: GeneralFormData.nationality?.value,
            lastName: GeneralFormData.lastName,
            firstName: GeneralFormData.firstName
          },
          residential: {
            address: GeneralFormData.StreetResidential,
            district: GeneralFormData.DistrictResidential?.value,
            province: GeneralFormData.ProvinceResidential?.value,
            ward: GeneralFormData.WardResidential?.value
          },
          allocate: {
            locationCode: GeneralFormData.AllocatedlocationCode,
            address: GeneralFormData.StreetAllocated,
            district: GeneralFormData.DistrictAllocated?.value,
            province: GeneralFormData.ProvinceAllocated?.value,
            ward: GeneralFormData.WardAllocated?.value
          },
          contact: {
            locationCode: GeneralFormData.locationCodeContact,
            address: GeneralFormData.StreetContact,
            district: GeneralFormData.DistrictContact?.value,
            province: GeneralFormData.ProvinceContact?.value,
            ward: GeneralFormData.WardContact?.value
          },
          bank: {
            bankAccount: GeneralFormData.Bankaccount,
            bankBranch: GeneralFormData.Bankbranchname?.value,
            bankCode: GeneralFormData.BankName?.value,
            bankName: props.detail.bankName,
            otherBank: GeneralFormData.BanknameOther,
            otherBankBranch: GeneralFormData.BankbranchnameOther,
            taxCode: GeneralFormData.Taxcode
          }
        },
        clientId: props.detail.clientId,
        correlationId: props.detail.correlationId,
        createdBy: props.detail.createdBy,
        createdDate: props.detail.createdDate,
        documents: props.detail.documents,
        errorMsg: props.detail.errorMsg,
        exchangeId: props.detail.exchangeId,
        functionUI: props.detail.functionUI,
        idNumber: props.detail.idNumber,
        isCCE: props.detail.isCCE,
        lasStatus: props.detail.lasStatus,
        location: props.detail.location,
        msgId: props.detail.msgId,
        officeCode: props.detail.officeCodes,
        otpTransaction: props.detail.otpTransaction,
        primaryPolicyNo: props.detail.primaryPolicyNo,
        refresh_token: props.detail.refresh_token,
        source: props.detail.source,
        systemName: props.detail.systemName,
        timestamp: props.detail.timestamp,
        transType: props.detail.transType
      }
      return pipe(
        ZIO.zipPar(AuthService.userInfo, DaGeneralInfoService.getGeneralInfoLog(props.detail.businessKey ?? '')),
        ZIO.map(([res, getLog]) => {
          const data: DaGeneralInfoService.GeneralInfoLogRqData = {
            eref: props.detail?.eREF,
            processInstanceId: props.detail.processInstanceId,
            taskId: props.detail?.taskId,
            agentCode: props.detail?.agentCode,
            clientId: props.detail?.idNo,
            businessKey: props.detail?.businessKey,
            author: res.email,
            source: props.detail.source,
            id: getLog.data?.id,
            qsCoreInfo: JSON.stringify(qsCoreInfo),
            applicationInfo: JSON.stringify(application),
            candidateInfo: JSON.stringify(candidateInfo),
            otherInfo: JSON.stringify(otherInfo),
            contactInfo: JSON.stringify(contactInfo),
            bankAccountInfo: JSON.stringify(bankAccountInfo),
            educationInfo: JSON.stringify(educationInfo),
            maritalStatus: JSON.stringify(maritalStatus),
            email: res.email,
            saleType: props.detail.salesType,
            status: JSON.stringify(GeneralFormData.isSaveGeral),
            submissionDate: props.detail.createdDate,
            transactionType: props.detail.transactionType,
            assessment: asSessment
          }
          return data
        }),
        ZIO.flatMap((data) => {
          return DaGeneralInfoService.saveGeneralInfoLog(data)
        }),
        ZIO.flatMap((data) => {
          return TaskDetailService.saveLog(props.detail.id ?? '', bodyPayload, props.detail.processInstanceId ?? '', [])
        }),
        ZIO.tap((data) => {
          props?.setStatusSave?.(true)
          showToast(t('message:MS100003'), 'success')
          props.checkSubTabIndex(1)
          props?.setIsScroll && props?.setIsScroll(!props?.isScroll)
          return ZIO.unit
        }),
        ZIO.tapError((e) => {
          return ZIO.unit
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  const getCheckLeaderCode = (leaderCode: string) => {
    pipe(
      DaGeneralInfoService.getLeaderCode(leaderCode ?? ''),
      ZIO.map((item) => {
        if (item.responseStatus?.code === '020' || item.data === 'Failed') {
          setRequiredLeaderCode(t('RequiredLeaderCode'))
          setCheckLeaderCode(true)
          setValue('leadercodedescription', '-')
          setValue('officecode', '-')
          setValue('saleZone', '-')
          setValue('regioncode', '-')
          setValue('regiondescription', '-')
        } else if (typeof item.data !== 'string') {
          setRequiredLeaderCode('')
          setCheckLeaderCode(false)
          setValue('leadercodedescription', item.data?.leaderCodeDescription ?? '-')
          setValue('officecode', item?.data?.officeCode ?? '-')
          setValue('saleZone', item?.data?.saleZone ?? '-')
          setValue('regioncode', item?.data?.regionCode ?? '-')
          setValue('regiondescription', item?.data?.regionDescription ?? '-')
        }
      }),
      ZIO.unsafeRun({})
    )
  }
  const saveCommentLeaderCode = (value: string, name: string) => {
    // setValue(`${name}`,value)
    switch (name) {
      case 'leaderCode':
        setValue(name, value)
        debounceLeaderCode(value)
      default:
        debounceLeaderCode(value)
        return ''
    }
  }
  const saveCommentAgentcoderefer = (value: string, name: string) => {
    // setValue(`${name}`,value)
    switch (name) {
      case 'agentcodeRefer':
        setValue(name, value)
        debounceAgentCodeRefef(value)
      default:
        debounceAgentCodeRefef(value)
        return ''
    }
  }
  const getCheckAgentCodeRefef = (agentCodeRefer: string) => {
    pipe(
      DaGeneralInfoService.getAgentCode(agentCodeRefer ?? ''),
      ZIO.map((item) => {
        if (getValues('agentcodeRefer') === '') {
          setAgentCodeRefer('')
        } else if (item.responseStatus?.code === '020') {
          setAgentCodeRefer(t('RequiredLeaderCode'))
          setValue('agentnameRefer', '-')
        } else {
          setAgentCodeRefer('')
          setValue('agentnameRefer', item?.data ?? '-')
        }
      }),
      ZIO.unsafeRun({})
    )
  }
  const debounceLeaderCode = React.useCallback(_.debounce(getCheckLeaderCode, 400), [])
  const debounceAgentCodeRefef = React.useCallback(_.debounce(getCheckAgentCodeRefef, 400), [])

  return (
    <View style={{ flex: 1 }}>
      <View>
        <Panel title={t('ApplicationInfo')} isExand={true}>
          <View style={{ flex: 1 }}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Input title={t('SubChannel')} required={true} value={setSubChannel()} disabled />
              </View>
              <View style={styles.cols}>
                <Input title={t('Salestype')} required={true} value={props.detail.salesType || '-'} disabled />
              </View>
              <View style={styles.col}>
                <Input title={t('eREF')} required={true} value={props.detail.eREF || '-'} disabled />
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Input
                    title={t('ChannelLink')}
                    required={true}
                    value={
                      detail.source === 'PRUFORCE'
                        ? setChannelLinkByPRUforce(detail, terReason)
                        : setChannelLinkByPRUdaily(detail, terReason)
                    }
                    disabled
                  />
                </View>
                <View style={styles.cols}>
                  <Input
                    title={t('SubmissionDate')}
                    required={true}
                    value={moment(props.detail.createdDate).format('DD/MM/yyyy') || '-'}
                    disabled
                  />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Input
                    title={t('Assessmentstatus')}
                    value={getValues('status') === true ? 'True' : 'False'}
                    disabled
                  />
                </View>
                <View style={styles.cols}>
                  <Input title={t('Assessmentdate')} value={getValues('assessmentDate') ?? '-'} disabled />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Input title={t('Assessbyrole')} value={getValues('role') ?? '-'} disabled />
                </View>

                <View style={styles.cols}>
                  <Input title={t('Assessbydesc')} value={getValues('assessByDesc') ?? '-'} disabled />
                </View>
              </View>
            </View>

            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Input title={t('Reassess')} value={getValues('reAssess') ?? '-'} disabled />
                </View>

                <View style={styles.cols}>
                  <Input title={t('ReassessDate')} value={getValues('reAssessDate') ?? '-'} disabled />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="agCode"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('AGCodeReinagent')}
                        // disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        maxLength={8}
                        inputType="number"
                      />
                    )}
                  />
                </View>
                <View style={styles.cols}>
                  <Input title={t('OCR')} required={true} value={props.detail.ocr || '-'} disabled />
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="leaderCode"
                    control={control}
                    render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                      <Input
                        title={t('Leadercode')}
                        onChange={(e) => {
                          setValidateField(true)
                          onChange
                          setComment(value ?? '')
                          setNameComment(name)
                        }}
                        onBlur={onBlur}
                        value={value ?? ''}
                        errorMessage={requiredLeaderCode}
                        maxLength={3}
                        required={true}
                      />
                    )}
                  />
                  {/* // )} */}
                </View>

                <View style={styles.cols}>
                  <Controller
                    name="leadercodedescription"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Leadercodedescription')}
                        disabled={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        errorMessage={error?.message}
                        maxLength={3}
                        required={true}
                      />
                    )}
                  />
                  {/* {!props.detail.newLeaderName ? (
                    <Input
                      title={t('Leadercodedescription')}
                      value={props.detail.leaderCodeDescription ?? ''}
                      disabled
                      required={true}
                    />
                  ) : (
                    <Input
                      title={t('Leadercodedescription')}
                      value={props.detail.newLeaderName ?? ''}
                      disabled
                      required={true}
                    />
                  )} */}
                </View>
                <View style={styles.col}>
                  <Controller
                    name="saleZone"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('SaleZone')}
                        disabled={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? '-'}
                        errorMessage={error?.message}
                        maxLength={3}
                        required={true}
                      />
                    )}
                  />
                  {/* <Input title={t('SaleZone')} required={true} value={props.detail.saleZone || '-'} disabled /> */}
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="officecode"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Officecode')}
                        disabled={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? '-'}
                        errorMessage={error?.message}
                        maxLength={3}
                        required={true}
                      />
                    )}
                  />
                  {/* <Input title={t('Officecode')} required={true} value={props.detail.officeCode || '-'} disabled /> */}
                </View>

                <View style={styles.cols}>
                  <Controller
                    name="regioncode"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Regioncode')}
                        disabled={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        // onBlur={() => setIsLeadercode(true)}
                        value={value ?? '-'}
                        errorMessage={error?.message}
                        maxLength={3}
                        required={true}
                      />
                    )}
                  />
                  {/* <Input title={t('Regioncode')} required={true} value={props.detail.regionCode || '-'} disabled /> */}
                </View>
                <View style={styles.col}>
                  <Controller
                    name="regiondescription"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Regiondescription')}
                        disabled={true}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? '-'}
                        errorMessage={error?.message}
                        maxLength={3}
                        required={true}
                      />
                    )}
                  />
                  {/* <Input
                    title={t('Regiondescription')}
                    required={true}
                    value={props.detail.regionDescription || '-'}
                    disabled
                  /> */}
                </View>
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Input
                    title={t('Oldunit')}
                    required={!(props.detail.transactionType === 'AGENT_ONBOARD_NEW')}
                    value={props.detail.transactionType === 'AGENT_ONBOARD_NEW' ? '-' : props.detail.oldUnit ?? '-'}
                    disabled
                  />
                </View>

                <View style={styles.cols}>
                  <Input
                    title={t('TerminatedDate')}
                    required={props.detail.transactionType === 'AGENT_ONBOARD_NEW' ? false : true}
                    value={props.detail.terdate || '-'}
                    disabled
                  />
                </View>
                <View style={styles.col}>
                  <Input
                    title={t('TerminationReason')}
                    required={props.detail.transactionType === 'AGENT_ONBOARD_NEW' ? false : true}
                    value={terReason ?? '-'}
                    disabled
                  />
                </View>
              </View>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <Panel title={t('Candidate')} isExand={true}>
          <View style={{ flex: 1 }}>
            <View style={styles.container}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: '800',
                      color: '#4F4F4F',
                      paddingBottom: 22
                    }}
                  >
                    {t('Check')}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Input title={t('IDNo')} required={true} value={props.detail.idNo || '-'} disabled />
                </View>

                <View style={styles.cols}>
                  <Controller
                    name="oldidNo"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('OldIDNo')}
                        // disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        maxLength={24}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="genDer"
                    control={control}
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: `${t('message:MS020009', { field: t('Gender') })}`
                    //   }
                    // }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        required={true}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('Gender')}
                        options={GeneralInformationSelect.getGenderOptions(i18n.language)}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('common:Select')}
                        errorMessage={error?.message}
                        // key={value}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={{ flex: 1, paddingVertical: 24 }}>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                          title={t('LastName')}
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          errorMessage={error?.message}
                          maxLength={100}
                          required={true}
                        />
                      )}
                    />
                  </View>

                  <View style={styles.cols}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                          title={t('FirstName')}
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          errorMessage={error?.message}
                          maxLength={100}
                          required={true}
                        />
                      )}
                    />
                  </View>
                  <View style={styles.col}>
                    <Controller
                      name="dateofBirth"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePicker
                          label={t('Dateofbirth')}
                          onChange={(val) => {
                            onChange(val)
                          }}
                          onBlur={onBlur}
                          value={value}
                          maxDateMessage={t('message:MS990032')}
                          errorMessage={error?.message}
                          // errorMessage={errors?.generalForm?.dateofBirth?.message}
                          required={true}
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </View>
                </View>
              </View>
              <View style={{}}>
                <TouchableOpacity
                  style={checkDup ? styles.buttonBlue : styles.buttonRed}
                  disabled={checkDup ? true : false}
                  onPress={() => {
                    checkDuplicate()
                  }}
                >
                  {checkDup ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        alignContent: 'center'
                      }}
                    >
                      <Text style={styles.textButtonBlue}>{t('Checked')}</Text>
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        alignContent: 'center'
                      }}
                    >
                      <Text style={styles.textButtonRed}>{t('CheckDuplicate')}</Text>
                    </View>
                  )}
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.container}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: '800',
                      color: '#4F4F4F',
                      paddingBottom: 22
                    }}
                  >
                    {t('OtherInformation')}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="nationality"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: `${t('message:MS020009', { field: t('Nationality') })}`
                      }
                    }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        required={true}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('Nationality')}
                        options={countries}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('common:Select')}
                        errorMessage={error?.message}
                        // key={value}
                      />
                    )}
                  />
                </View>
                <View style={styles.cols}>
                  <Controller
                    name="ethNic"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Ethnic')}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        maxLength={24}
                        required={false}
                      />
                    )}
                  />
                </View>
                <View style={styles.col}>
                  <Controller
                    name="placeofBirth"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Placeofbirth')}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        errorMessage={error?.message}
                        maxLength={100}
                        required={true}
                      />
                    )}
                  />
                </View>
              </View>
              <View style={{ flex: 1, paddingVertical: 24 }}>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Controller
                      name="idDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePicker
                          label={t('IDdate')}
                          // onChange={(val) => {
                          //   onChange(val)
                          // }}
                          onChange={(val) => {
                            onChange(val)
                          }}
                          //onBlur={onBlur}
                          value={value}
                          // maxDate={currentDate}
                          maxDateMessage={t('message:MS990032')}
                          // minDate={result}
                          // errorMessage={errors.generalForm?.idDate?.message}
                          errorMessage={error?.message}
                          required={true}
                        />
                      )}
                    />
                  </View>
                  <View style={styles.cols}>
                    <Controller
                      name="idPlace"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                          title={t('IDplace')}
                          disabled={false}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          errorMessage={error?.message}
                          maxLength={100}
                          required={true}
                        />
                      )}
                    />
                  </View>
                  <View style={styles.col}>
                    <Controller
                      name="idExpireddate"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePicker
                          label={t('IDexpireddate')}
                          onChange={(val) => {
                            onChange(val)
                          }}
                          onBlur={onBlur}
                          value={value}
                          minDate={new Date()}
                          // errorMessage={errors?.generalForm?.idExpireddate?.message}
                          invalidDateMessage={null}
                          required={false}
                          highlightedField={newDate >= moment(value).valueOf()}
                          alwaysShow
                        />
                      )}
                    />
                  </View>
                </View>
              </View>
              <View style={{ flex: 1, paddingVertical: 24 }}>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Controller
                      name="agentcodeRefer"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
                        <Input
                          title={t('Agentcoderefer')}
                          disabled={false}
                          onChange={(val) => {
                            setValidateField(true)
                            onChange
                            setComment(value ?? '')
                            setNameComment(name)
                          }}
                          onBlur={onBlur}
                          value={value ?? ''}
                          errorMessage={agentCodeRefer}
                          maxLength={8}
                          inputType="number"
                        />
                      )}
                    />
                  </View>
                  <View style={styles.cols}>
                    <Controller
                      name="agentnameRefer"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Input
                          title={t('Agentnamerefer')}
                          disabled={true}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          // errorMessage={error?.message}
                          maxLength={100}
                        />
                      )}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <Panel title={t('Contact')} isExand={true}>
          <View style={{ flex: 1 }}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="phonenumber"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('Phonenumber')}
                      disabled={true}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ''}
                      errorMessage={error?.message}
                      maxLength={10}
                      required={false}
                      inputType="number"
                    />
                  )}
                />
              </View>

              <View style={styles.cols}>
                <Controller
                  name="companyphone"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('Companyphone')}
                      // disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ''}
                      // errorMessage={error?.message}
                      maxLength={12}
                      inputType="number"
                    />
                  )}
                />
              </View>
              <View style={styles.col}>
                <Controller
                  name="HomePhone"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('Homephone')}
                      // disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ''}
                      // errorMessage={error?.message}
                      maxLength={12}
                      inputType="number"
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="Email"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Email')}
                        // disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        errorMessage={error?.message}
                        maxLength={100}
                        required={true}
                      />
                    )}
                  />
                </View>

                <View style={styles.cols}>
                  <Controller
                    name="Zalo"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Zalo')}
                        // disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        maxLength={10}
                        inputType="number"
                      />
                    )}
                  />
                </View>
              </View>
            </View>
            <AllocatedAddress
              form={props.form}
              detail={props.detail}
              provinceData={provinces}
              agentGeneralData={agentGeneralInfo}
            />
            <ResidentialAddress
              form={props.form}
              detail={props.detail}
              provinceData={provinces}
              agentGeneralData={agentGeneralInfo}
            />
            <ContactAddress
              form={props.form}
              detail={props.detail}
              provinceData={provinces}
              agentGeneralData={agentGeneralInfo}
            />
          </View>
        </Panel>
      </View>
      <View>
        <Panel title={t('BankaCcount')} isExand={true}>
          <View style={{ flex: 1 }}>
            <BankAccount form={props.form} detail={props.detail} />
            <BankAccountOther form={props.form} detail={props.detail} />
          </View>
        </Panel>
      </View>
      <View>
        <Panel title={t('EducationOccupation')} isExand={true}>
          <View style={{ flex: 1 }}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="Education"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020009', { field: t('Education') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      required={true}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      label={t('Education')}
                      options={GeneralInformationSelect.getEducationOptions(i18n.language)}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('common:Select')}
                      errorMessage={error?.message}
                      // key={value}
                    />
                  )}
                />
              </View>
              <View style={styles.cols}>
                <Controller
                  name="Specialization"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('Specialization')}
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ''}
                      // errorMessage={error?.message}
                      maxLength={100}
                    />
                  )}
                />
              </View>
              <View style={styles.col}>
                {/* /// GOI API DANH SACH NGHE NGHIEP */}
                <Controller
                  name="Occupation"
                  control={control}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: `${t('message:MS020009', { field: t('Occupation') })}`
                  //   }
                  // }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      required={true}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      label={t('Occupation')}
                      options={occupations}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('common:Select')}
                      errorMessage={error?.message}
                      // key={value}
                    />
                  )}
                />
              </View>
            </View>
            <View style={{ flex: 1, paddingVertical: 24 }}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <Controller
                    name="Income"
                    control={control}
                    // rules={{
                    //   required: {
                    //     value: true,
                    //     message: `${t('message:MS020009', { field: t('Income') })}`
                    //   }
                    // }}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <SelectSearch
                        required={false}
                        popupIcon={<assets.ArrowDownDropdownIcon />}
                        label={t('Income')}
                        options={GeneralInformationSelect.getIncomeOptions(i18n.language)}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder={t('common:Select')}
                        // errorMessage={error?.message}
                        // key={value}
                      />
                    )}
                  />
                </View>
                <View style={styles.cols}>
                  <Controller
                    name="Taxcode"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <Input
                        title={t('Taxcode')}
                        disabled={false}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        // errorMessage={error?.message}
                        maxLength={50}
                        inputType="number"
                      />
                    )}
                  />
                </View>
              </View>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <Panel title={t('MaritalStatus')} isExand={true}>
          <View style={{ flex: 1, paddingBottom: 20 }}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Controller
                  name="Maritalstatus"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020009', { field: t('Maritalstatus') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <SelectSearch
                      required={true}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      label={t('Maritalstatus')}
                      options={GeneralInformationSelect.getMaritalOptions(i18n.language)}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={t('common:Select')}
                      errorMessage={error?.message}
                      // key={value}
                    />
                  )}
                />
              </View>
              <View style={styles.cols}>
                <Controller
                  name="Numberofchildren"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      title={t('Numberofchildren')}
                      disabled={false}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ''}
                      // errorMessage={error?.message}
                      maxLength={2}
                      inputType="number"
                    />
                  )}
                />
              </View>
            </View>
          </View>
        </Panel>
      </View>
      {props.isHistory === true ? null : (
        <Footer isLoading={loading} onReset={onReset} onSubmit={onSave} disableSave={!getValues('checkDuplicate')} />
      )}
      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
      {validateField && (
        <LeaderCodeModal
          onClose={() => setValidateField(false)}
          open={validateField}
          onSetComment={nameComment === 'leaderCode' ? saveCommentLeaderCode : saveCommentAgentcoderefer}
          name={nameComment}
          comment={comment}
        />
      )}
    </View>
  )
})
const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  },
  container: {
    width: '100%',
    padding: 30,
    backgroundColor: '#F4F4F4',
    borderRadius: 8,
    marginBottom: 16
  },
  containers: {
    width: '100%',
    padding: 30,
    borderRadius: 8,
    marginBottom: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6'
  },
  col: {
    width: '33.33333333333%'
  },
  cols: {
    width: '33.33333333333%',
    paddingHorizontal: 30
  },
  row: {
    flexDirection: 'row'
  },
  buttonRed: {
    borderRadius: 100,
    backgroundColor: '#ED1B2E',
    height: 36,
    width: 170,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  buttonBlue: {
    borderRadius: 100,
    backgroundColor: '#4CAF50',
    height: 30,
    width: 135,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center'
  },
  textButtonRed: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' },
  textButtonBlue: { textAlign: 'center', color: '#FFF', fontWeight: 'bold' }
})
