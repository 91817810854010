import React from 'react'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'

import {
  assets,
  Container,
  ErrorHandling,
  FieldList,
  GeneralInfoService,
  Panel,
  TableField,
  useMobile,
  useTranslator,
  Checkbox,
  ModalComponent,
  HistoryVulnerableCustomerDetail,
  TaskType
} from '@pulseops/common'
import { PolicyInfoHeader } from '../../common'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Tab } from 'react-native-elements'
import { useIsFocused } from '@react-navigation/native'

const menus = [
  {
    label: 'Policy number',
    icon: <assets.DocumentIcon />,
    value: '7045867'
  },
  {
    label: 'Policy owner',
    icon: <assets.OwnerIcon />,
    value: 'NGUYEN VAN A'
  }
]

export interface CustomerInfoData {
  contactInfoFields: FieldList.DataSources
  personalInfoSource: TableField.DataSources
  hasVulnerableCustomer?: boolean
  POClientNumber?: string
}

export const CustomerScreen = (props: {
  id: string
  data: CustomerInfoData | null
  setData: (val: CustomerInfoData) => void
  caterogy?: string
  isGeneral?: boolean
}) => {
  const { isMobile } = useMobile()
  const [loading, bindLoading] = useLoading()
  const { t } = useTranslator('TaskManagement')
  const cct = useTranslator('classCode').t
  const ct = useTranslator('contact').t
  const rolesT = useTranslator('roles').t
  const { t: tCommon } = useTranslator('common')
  const requestInfoT = useTranslator('requestInfo').t
  const isFocused = useIsFocused()
  const [hasOpenedVulnerableCustomerPopup, setHasOpenedVulnerableCustomerPopup] = React.useState(false)
  const [historicVulnerableCustomerList, setHistoricVulnerableCustomerList] = React.useState<Array<HistoryVulnerableCustomerDetail>>([])

  // const [contactInfoFields, setContactInfoFields] = useState<FieldList.DataSources>([])
  // const [personalInfoSource, setPersonalInfoSource] = useState<TableField.DataSources>([])

  function getClassCode(code: string) {
    switch (code) {
      case 'V':
        return cct('Gold')
      case 'P':
        return cct('Platinum')
      case 'D':
        return cct('Diamond')
      default:
        return cct('Basic')
    }
  }

  React.useEffect(() => {
    return () => {
      setHasOpenedVulnerableCustomerPopup(false)
      setHistoricVulnerableCustomerList([])
    }
  }, [isFocused])

  const hasVulnerableCustomerCategory = [TaskType.PolicyService, TaskType.Complaint, TaskType.CustomerService].includes(props.caterogy as TaskType) || props.isGeneral === false

  const getData = (): ZIO<unknown, Throwable, CustomerInfoData> =>
    pipe(
      GeneralInfoService.getCustomerInfo(props.id, hasVulnerableCustomerCategory),
      ZIO.flatMap((_customerInfo) =>
        ZIO.effect(() => {
          return _customerInfo
            ? {
              contactInfoFields: [
                {
                  label: ct('Address'),
                  value: _customerInfo.street
                },
                {
                  label: ct('Ward'),
                  value: _customerInfo.ward
                },
                {
                  label: ct('District'),
                  value: _customerInfo.district
                },
                {
                  label: ct('City'),
                  value: _customerInfo.city
                },
                {
                  label: ct('Country'),
                  value: _customerInfo.country
                },
                {
                  label: t('VIP'),
                  value: getClassCode(_customerInfo.vip)
                },
                {
                  label: ct('Email'),
                  value: _customerInfo.email
                },
                {
                  label: ct('MobilePhone'),
                  value: _customerInfo.mobilePhone
                },
                {
                  label: t('CompanyPhone'),
                  value: _customerInfo.companyPhone
                },
                {
                  label: t('SMSEmailIndicator'),
                  value: (
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ marginRight: 30 }}>
                        <Checkbox title="SMS" value={_customerInfo.smsInd} />
                      </View>
                      <View>
                        <Checkbox title="Email" value={_customerInfo.smsInd} />
                      </View>
                    </View>
                  )
                }
              ],
              personalInfoSource: _customerInfo.po_la_info.map((x) => ({
                ...x,
                gender: x.gender === 'Male' || x.gender === 'F' ? tCommon('Male') : tCommon('Female'),
                role: rolesT(x.role)
              })),
              hasVulnerableCustomer: _customerInfo.hasVulnerableCustomer || false,
              POClientNumber: _customerInfo.POClientNumber
            }
            : {
              contactInfoFields: [],
              personalInfoSource: [],
              hasVulnerableCustomer: false,
              POClientNumber: ''
            }
        })
      )
    )
  const { contactInfoFields, personalInfoSource, hasVulnerableCustomer, POClientNumber } = pipe(
    props.data ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) props.setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidUpdate([props.data])
  ) || {
    contactInfoFields: [],
    personalInfoSource: []
  }

  const columns = [
    {
      key: '1',
      title: ct('Role'),
      name: 'role'
    },
    {
      key: '2',
      title: ct('Fullname'),
      name: 'name'
    },
    {
      key: '3',
      title: requestInfoT('IDNumber'),
      name: 'nationalId'
    },
    {
      key: '4',
      title: requestInfoT('DateOfBirth'),
      name: 'dob'
    },
    {
      key: '5',
      title: ct('Gender'),
      name: 'gender'
    }
  ]

  const onHandleOpendVulnerableCustomerPopup = () => {
    pipe(
      GeneralInfoService.getHistoryVulnerableCustomerDetail(POClientNumber || ''),
      ZIO.map((vulnerableCustomerData) => {
        setHasOpenedVulnerableCustomerPopup(true)
        vulnerableCustomerData && vulnerableCustomerData.length > 0 ? setHistoricVulnerableCustomerList(vulnerableCustomerData) :
          setHistoricVulnerableCustomerList([])
        return vulnerableCustomerData
      }),
      ErrorHandling.run()
    )
  }

  const vulnerableCustomerPopup = () => {
    return (
      <ModalComponent
        title={t('TaskManagement:VulnerableCustomer')}
        visible={hasOpenedVulnerableCustomerPopup}
        onClose={() => { setHasOpenedVulnerableCustomerPopup(false) }}
        centerTitle={true}
        actions={[]}
      >
        <View style={customerStyles.modalContent}>
          {/* <Text>Vulnerable Customer</Text> */}
          <TableContainer component={Paper}>
            <TableHead>
              <TableRow style={{ backgroundColor: '#e2e7ea' }}>
                <TableCell style={{ width: '18%', fontWeight: 'bold', fontSize: 15 }}>{t('TaskManagement:VCType')}</TableCell>
                <TableCell style={{ width: '25%', fontWeight: 'bold', fontSize: 15 }}>{t('TaskManagement:Description')}</TableCell>
                <TableCell style={{ width: '30%', fontWeight: 'bold', fontSize: 15 }}>{t('TaskManagement:EffectiveDateFrom')}</TableCell>
                <TableCell style={{ width: '27%', fontWeight: 'bold', fontSize: 15 }}>{t('TaskManagement:EffectiveDateTo')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {historicVulnerableCustomerList && historicVulnerableCustomerList.length > 0 && historicVulnerableCustomerList.map((item, index) => {
                return (
                  <TableRow key={`VulnerableCustomerList_${index}`}>
                    <TableCell>{item.vcType}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.effectiveDateFrom}</TableCell>
                    <TableCell>{item.effectiveDateTo}</TableCell>
                  </TableRow>
                )
              })}
              {/* <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Thành Viên Hộ nghèo</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
             */}
            </TableBody>
          </TableContainer>
        </View>
      </ModalComponent>
    )
  }

  return (
    <Container loading={loading}>
      {isMobile && <PolicyInfoHeader menus={menus} />}
      {
        contactInfoFields?.length === 0 && personalInfoSource.length === 0 ? <View>
          <Text style={{ textAlign: 'center' }}>No data</Text>
        </View> : ''
      }
      {hasVulnerableCustomer && hasVulnerableCustomerCategory && (
        <View style={customerStyles.messageContainer}>
          <Text style={customerStyles.highLightText}>{t("message:MS020088")}</Text>
          <Pressable onPress={() => { onHandleOpendVulnerableCustomerPopup() }}>
            <Text style={customerStyles.viewText}>{t('Adjudication:ViewDetail')}</Text>
          </Pressable>
        </View>
      )}
      {contactInfoFields?.length > 0 && (
        <Panel title={t('ContactInfo').toUpperCase()}>
          <FieldList dataSource={contactInfoFields} />
        </Panel>
      )}
      {personalInfoSource.length > 0 && (
        <Panel title={t('ClientInfo').toUpperCase()}>
          <TableField columns={columns} dataSource={personalInfoSource} currency="" />
        </Panel>
      )}
      {hasVulnerableCustomerCategory && vulnerableCustomerPopup()}
    </Container>
  )
}
const customerStyles = StyleSheet.create({
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15
  },
  highLightText: {
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold',
    color: '#ED1B2E',
  },
  viewText: {
    marginLeft: 5,
    color: 'blue',
    textDecorationLine: 'underline'
  },
  modalContent: {
    marginHorizontal: 20
  }
})