import { SubmissionService } from '../SubmissionService'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { CancelRider } from './CancelRider'
import { InquiryComplaintData } from './InquiryComplaint'

export interface PolicyInfo {
  policyOwner: string
  mainLA: string
  APLDate: string
  contractName: string
  policyStatus: string
  premiumStatus: string
  sumAssured: string
  installmentPremium: string
  frequency: string
  firstIssuedDate: string
  policyIssuedDate: string
  riskCommenceDate: string
  paidToDateBasic: string
  premiumCessDate: string
  riskCessDate: string
  paidToDateRider: string
  customerReceiveDate: string
  customerReceiveMethod: string
  bensData: {
    name: string
    percentage: string
    relationship: string
  }[]
  statuses: ('PENDING' | 'IN_PROCESS')[]
  hasSpecialNote: boolean
  warningList: SubmissionService.WarningMessage[]
  contractNameRaw: {
    en: string
    vi: string
  }
}

export interface POLAInfo {
  role: string
  name: string
  nationalId: string
  dob: string
  gender: string
}

export interface CustomerInfo {
  street: string
  ward: string
  district: string
  city: string
  country: string
  vip: string
  email: string
  mobilePhone: string
  companyPhone: string
  isDuplicatedPhoneNumber: boolean
  emailInd: boolean
  smsInd: boolean
  po_la_info: POLAInfo[]
  hasVulnerableCustomer?: boolean
  POClientNumber: string
}

export interface PI_detail {
  componentName: string
  status: string
  sumAssured: string
  installmentPremium: string
  paidToDate: string
  premiumCessationDate: string
}

export interface ProductInfo {
  name?: string | null
  life: string
  extInfos: PI_detail[]
}

export interface ProductExtInfo {
  owner: ProductInfo | null
  laClient: ProductInfo[]
}

export interface PVI_bens {
  cashReleaseDate: string
  principalAmount: string
  interestAmount: string
  totalPerPeriod: string
  receivedDate: string
  receiveAmount: string
}

export interface PVI_APL_OPL {
  loanDesc: string
  transDate: string
  principalAmount: string
  currBalance: string
  accuredInterest: string
  pendingInterest: string
  loanNumber: number
}
export interface PolicyValueInfo {
  APL: string
  OPL: string
  surrVal: string
  loanAvalable: string
  loan80: string
  bonusVal: string
  specialBonus: string
  bonusSurr: string
  suspAmt: string
  prucashBen: string
  PruKid: string
  maturityBen: string
  maxPwAmt: string
  apl_opl_detail: PVI_APL_OPL[]
  prucash_ben: PVI_bens[]
}

export interface InvestmentInfo_ILP {
  fundValue: {
    fundName: string
    unitBalance: string
    estimatedValue: string
  }[]
  partialWithdrawalAmount: {
    fundName: string
    partialWithdrawal: string
  }[]
}

export interface InvestmentInfo_ULP {
  totalEstimateValue?: string
  ilpPartialWithdrawal?: string
}

export interface InvestmentInfo {
  ilp?: InvestmentInfo_ILP
  ulp?: InvestmentInfo_ULP
}

export interface Agent {
  // idNum: string,
  // name: string,
  // telCode: string,
  // mobilePhone: string
  name: string
  mobilePhone: string
}

export interface UnitManager extends Agent {
  // officeCode: string,
  // officeName: string
  officeData: string
}

export interface BranchManager extends Agent {
  //  gaCode: string,
  //  gaName: string
  gaData: string
}

export interface BusinessDevManager extends Agent {
  regionCode: string
}

export interface OldAgent extends Agent {
  terminateDate: string
}

export interface AgentInfoDetail {
  servicingAgent: Agent
  sharingAgent: Agent
  unitManager: UnitManager
  branchManager: BranchManager
  businessDevManager: BusinessDevManager
}

export interface AgentInfo {
  agentInfo: AgentInfoDetail
  oldAgentInfo: OldAgent[]
}

//IF Quotation data type

export interface IFTerminalBonusRates {
  prefix: string
  id: string
  crTable: string
  tier: string
  period: string
  rateLow: number
  rateMedium: number
  rateHigh: number
}

export const TerminalBonusRates = t.type({
  prefix: t.string,
  id: t.string,
  crTable: t.string,
  tier: t.string,
  period: t.string,
  rateLow: t.string,
  rateMedium: t.string,
  rateHigh: t.string
})

export const couponInterestRate = t.type({
  prefix: t.string,
  id: t.string,
  crTable: t.string,
  rateLow: t.string,
  rateMedium: t.string,
  rateHigh: t.string
})

export const PruOnlineQuotationData = t.type({
  maturityDate: t.string,
  currentValue: t.string,
  reversionaryBonus: t.string,
  terminalBonus: t.string,
  cashBenefitBalance: t.string,
  guaranteedBenefitValue: Maybe(t.string),
  maturityAdvance: t.string,
  surrenderValue: t.string,
  projectedValue: t.string,
  projectedReversionaryBonusLow: t.string,
  projectedReversionaryBonusMedium: t.string,
  projectedReversionaryBonusHigh: t.string,
  projectedTerminalBonusLow: t.string,
  projectedTerminalBonusMedium: t.string,
  projectedTerminalBonusHigh: t.string,
  projectedCashBenefitBalanceLow: t.string,
  projectedCashBenefitBalanceMedium: t.string,
  projectedCashBenefitBalanceHigh: t.string,
  projectedGuaranteedBenefit: t.string,
  projectedSurrenderValueLow: t.string,
  projectedSurrenderValueMedium: t.string,
  projectedSurrenderValueHigh: t.string,
  terminalBonusRates: t.array(TerminalBonusRates),
  reversionaryBonusRates: t.array(TerminalBonusRates),
  couponInterestRate: couponInterestRate,
  lastBonusDate: t.string
})

export const PruOnlineQuotationType = t.type({
  status: Maybe(t.string),
  tranDate: t.string,
  result: PruOnlineQuotationData
})

export const BenefitsPrintData = t.type({
  benefits: t.array(
    t.type({
      lifeAssuredName: t.string,
      components: t.array(
        t.type({
          name: t.string,
          riskCessDate: t.string,
          sumAssured: t.string
        })
      )
    })
  )
})

export const PolicyValuePrintData = t.type({
  policyValue: t.array(
    t.type({
      title: t.string,
      value: t.string
    })
  )
})

export const ProjectValuePrintData = t.type({
  projectValue: t.array(
    t.type({
      name: t.string,
      lowValue: t.string,
      mediumValue: t.string
    })
  )
})

export const BonusRating = t.array(
  t.type({
    year: t.string,
    tier: t.string,
    lowRate: t.number,
    mediumRate: t.number,
    highRate: t.number
  })
)

export const ReversionaryBonusRating = t.type({
  reversionaryBonusRating: BonusRating
})

export const TerminalBonusRating = t.type({
  terminalBonusRating: BonusRating
})

export const IFQuotationPrintData = t.intersection([
  t.type({
    componentNameBasic: t.string,
    componentNameCode: t.string,
    policyNumber: t.string,
    printingDate: t.string,
    policyOwnerName: t.string,
    policyRiskCessDate: t.string,
    couponInterestRateLow: t.string,
    couponInterestRateMedium: t.string,
    couponInterestRateHigh: t.string,
    benefits: t.array(
      t.type({
        lifeAssuredName: t.string,
        components: t.array(
          t.type({
            name: t.string,
            riskCessDate: t.string,
            sumAssured: t.string
          })
        )
      })
    )
  }),
  PolicyValuePrintData,
  ProjectValuePrintData,
  ReversionaryBonusRating,
  TerminalBonusRating
])

export const CheckingVulnerableCustomerResponse = t.type({
  isVulnerable: Maybe(t.boolean),
  messageCode: t.string
})

export const HistoryVulnerableCustomerDetail = t.type({
  vcType: t.string,
  description: t.string,
  effectiveDateFrom: t.string,
  effectiveDateTo: t.string
})
export type PruOnlineQuotationData = t.TypeOf<typeof PruOnlineQuotationData>
export type TerminalBonusRates = t.TypeOf<typeof TerminalBonusRates>
export type IFQuotationPrintData = t.TypeOf<typeof IFQuotationPrintData>
export type PolicyValuePrintData = t.TypeOf<typeof PolicyValuePrintData>
export type BonusRating = t.TypeOf<typeof BonusRating>
export type HistoryVulnerableCustomerDetail = t.TypeOf<typeof HistoryVulnerableCustomerDetail>
export type CheckingVulnerableCustomerResponse = t.OutputOf<typeof CheckingVulnerableCustomerResponse>

export interface IFQuotationInfo {
  LAList: CancelRider.LARiderInfo[]
  riderNameList: InquiryComplaintData.IACSources[]
  sourceList: InquiryComplaintData.CSSources[]
  mainRiskCessDate: string
  poName: string
  poCMND: string
  pruOnlineQuotationData: PruOnlineQuotationData
}
