import React, { useState, useEffect, useContext } from 'react'
import { ReportsStackParamList } from './ReportsStackParamList'
import { StackScreenProps } from '@react-navigation/stack'
import { useIsFocused } from '@react-navigation/native'
import {
  AppContext,
  DatePicker,
  // ErrorHandling,
  // RBAC,
  SelectOption,
  SelectSearch,
  assets,
  // ReportPermission,
  GeneralService,
  AuthService,
  PulseOpsFormat,
  T0Data,
  Format
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'react-i18next'
import { ScrollView, View, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { SubmissionReportConst } from './SubmissionReportConst'
import { ZIO } from '@mxt/zio'

import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  LinearProgress
} from '@material-ui/core'
import moment from 'moment'
import { isAfter, startOfDay, addDays } from 'date-fns/fp'
import { PaymentReportService, PaymentReportItemType } from './Service/PaymentReportService'
import { isEmpty, isNil } from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { ReportService } from './Service/ReportExcelService'
import _ from 'lodash'
export interface PaymentReportFormData {
  fromDate: Date | null
  toDate: Date | null
  template: SelectOption
  offices: SelectOption[]
  category: SelectOption
  source: SelectOption[]
  reportType: SelectOption
}

declare interface Pagination {
  pageNum: number
  pageSize: number
  total: number
}

declare type actionType = 'VIEW' | 'EXPORT'

const Action = {
  VIEW: 'VIEW',
  EXPORT: 'EXPORT'
}

type props = StackScreenProps<ReportsStackParamList, 'PaymentReportScreen'>

declare type DocumentStatusType = 'VALID_DOC' | 'INVALID_DOC' | 'INCOMPLETE_DOC' | 'NOT_UPDATE_DOC'

export const Status = {
  VALID: 'VALID_DOC',
  INVALID: 'INVALID_DOC',
  INCOMPLETE: 'INCOMPLETE_DOC',
  NOTUPDATE: 'NOT_UPDATE_DOC'
}

export const PaymentReportScreen = ({ navigation }: props) => {
  const isFocused = useIsFocused()
  const { t, i18n } = useTranslation()
  const [loading, bindLoading] = useLoading(false)
  const ht = useTranslation('menu').t
  const titleHeaders = [
    t('Reports:Payment.TableHeader.Policy'),
    t('Reports:Payment.TableHeader.PolicyOwner'),
    t('Reports:Payment.TableHeader.Payee'),
    t('Reports:Payment.TableHeader.IDNumber'),
    t('Reports:Payment.TableHeader.AgentCode'),
    t('Reports:Payment.TableHeader.PaymentNumber'),
    t('Reports:Payment.TableHeader.PVNumber'),
    t('Reports:Payment.TableHeader.PaymentDate'),
    t('Reports:Payment.TableHeader.Amount'),
    t('Reports:Payment.TableHeader.PaymentOffice'),
    t('Reports:Payment.TableHeader.BankCode'),
    t('Reports:Payment.TableHeader.PVStatus'),
    t('Reports:Payment.TableHeader.TransactionType'),
    t('Reports:Payment.TableHeader.CreatedDate'),
    t('Reports:Payment.TableHeader.CreatedBy'),
    t('Reports:Payment.TableHeader.VerifiedDate'),
    t('Reports:Payment.TableHeader.VerifiedBy'),
    t('Reports:Payment.TableHeader.AuthorizedDate'),
    t('Reports:Payment.TableHeader.AuthorizedBy'),
    t('Reports:Payment.TableHeader.Status'),
    t('Reports:Payment.TableHeader.Note')
  ]

  const titleHeadersForGA = [
    t('Reports:Payment.TableHeader.Policy'),
    t('Reports:Payment.TableHeader.PolicyOwner'),
    t('Reports:Payment.TableHeader.Payee'),
    t('Reports:Payment.TableHeader.IDNumber'),
    // t('Reports:Payment.TableHeader.AgentCode'),
    t('Reports:Payment.TableHeader.PaymentNumber'),
    t('Reports:Payment.TableHeader.PVNumber'),
    t('Reports:Payment.TableHeader.PaymentDate'),
    t('Reports:Payment.TableHeader.Amount'),
    t('Reports:Payment.TableHeader.PaymentOffice'),
    t('Reports:Payment.TableHeader.BankCode'),
    t('Reports:Payment.TableHeader.PVStatus'),
    t('Reports:Payment.TableHeader.TransactionType'),
    t('Reports:Payment.TableHeader.CreatedDate'),
    t('Reports:Payment.TableHeader.CreatedBy'),
    t('Reports:Payment.TableHeader.VerifiedDate'),
    t('Reports:Payment.TableHeader.VerifiedBy'),
    t('Reports:Payment.TableHeader.AuthorizedDate'),
    // t('Reports:Payment.TableHeader.AuthorizedBy'),
    t('Reports:Payment.TableHeader.Status'),
    t('Reports:Payment.TableHeader.Note')
  ]

  const Status = {
    VALID: 'VALID_DOC',
    INVALID: 'INVALID_DOC',
    INCOMPLETE: 'INCOMPLETE_DOC',
    NOTUPDATE: 'NOT_UPDATE_DOC'
  }

  const optionStatusNote = [
    {
      value: Status.INCOMPLETE,
      label: t('CashOut:NotCompleteDoc')
    },
    {
      value: Status.NOTUPDATE,
      label: t('CashOut:NotUpdateDoc')
    },
    {
      value: Status.VALID,
      label: t('CashOut:ValidDoc')
    },
    {
      value: Status.INVALID,
      label: t('CashOut:InvalidDoc')
    }
  ]

  const { changeBreadcrumb, showToast, showGlobalLoading } = useContext(AppContext.AppContextInstance)
  const [maxDate] = React.useState<Date>(new Date())
  const [officeCodes, setOfficeCodes] = useState<SelectOption[]>([])
  const [officeGA, setOfficeGA] = useState<SelectOption | null>(null)
  const [dataSource, setDataSource] = useState<PaymentReportItemType[]>([])

  const [{ pageNum, pageSize, total }, setPagination] = useState<Pagination>({
    pageNum: 0,
    pageSize: 10,
    total: 0
  })

  const _totalAmount = dataSource?.reduce((acc, curr) => {
    return (acc += curr.amount)
  }, 0)

  const actions = [
    {
      type: Action.VIEW,
      text: t('View'),
      isHavePermission: true
    },
    {
      type: Action.EXPORT,
      text: t('Export'),
      isHavePermission: true
    }
  ]

  const totals = [
    {
      title: t('Reports:TotalPayment'),
      value: total
    },
    {
      title: t('Reports:TotalAmount'),
      value: PaymentReportService.formatCurrency(_totalAmount)
    }
  ]

  const paymentReportForm = useForm<PaymentReportFormData>({
    defaultValues: {
      fromDate: undefined,
      toDate: undefined,
      offices: officeGA ? [officeGA] : [],
      template: {
        value: SubmissionReportConst.Extension.EXCEL,
        label: 'Excel'
      },
      reportType: {
        value: SubmissionReportConst.Report.SM,
        label: 'Payment Report For GA'
      }
    }
  })

  const { control, setValue, watch } = paymentReportForm

  const loadOffices = () => {
    return pipe(
      GeneralService.getALLOfficeList(),
      ZIO.map((res) => {
        return res.filter(
          (elm) =>
            elm.type === 'GA' || elm.type === 'TO' || ['VCO', 'PRU', 'DNG', 'HCM', 'NTG', 'HNI'].includes(elm.code)
        )
      }),
      ZIO.zipWith(AuthService.userInfo),
      ZIO.flatMap(([codes, userInfo]) =>
        pipe(
          userInfo.officeCode ? GeneralService.getOfficeCode(userInfo.officeCode) : ZIO.succeed({ body: [] }),
          ZIO.map((officeGA) => ({ codes, userInfo, officeGA: officeGA.body }))
        )
      ),
      ZIO.map(({ codes, userInfo, officeGA }) => {
        setOfficeCodes(
          codes.map((code) => ({
            value: code.code,
            label: i18n.language === 'vi' ? code.nameVi : code.nameEn
          }))
        )
        if (userInfo.officeCode) {
          const office = officeCodes.find((code) => code.value === userInfo.officeCode)
          if (office) {
            setOfficeGA(office)
            setValue('offices', [office])
          } else {
            const office = {
              value: userInfo.officeCode,
              label: (i18n.language === 'en' ? officeGA[0]?.nameEn : officeGA[0]?.nameVi) || userInfo.officeCode
            }
            setOfficeCodes([office])
            setOfficeGA(office)
            setValue('offices', [office])
          }
        }
      }),
      ZIO.unsafeRun({})
    )
  }

  useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        {
          title: ht('Reports'),
          navigate: () => {
            navigation.navigate('ReportsScreen')
          }
        },
        {
          title: t('Reports:PaymentReport'),
          navigate: null
        }
      ])
    }

    loadOffices()
  }, [isFocused])

  /**
   * Functions
   */

  const validateDataFields = async (fromDateItem: Date, toDateItem: Date) => {
    const customedFromDate = moment(fromDateItem).format('YYYY/MM/DD')
    const customedToDate = moment(toDateItem).format('YYYY/MM/DD')

    const isValidForm = await paymentReportForm.trigger()

    if (!isValidForm) {
      return false
    } else if (!moment(fromDateItem).isValid()) {
      return false
    } else if (!moment(customedToDate).isValid()) {
      return false
    } else if (fromDateItem && !toDateItem) {
      return false
    } else if (!fromDateItem && toDateItem) {
      return false
    } else if (moment(customedFromDate) > moment(customedToDate)) {
      showToast(t('message:MS030044'), 'error')
      return false
    } else if (
      moment(customedFromDate).valueOf() > moment().valueOf() ||
      moment(customedToDate).valueOf() > moment().valueOf()
    ) {
      return false
    } else if (
      pipe(fromDateItem, startOfDay, addDays(35), (fromDateAdd10Days) =>
        isAfter(fromDateAdd10Days)(startOfDay(toDateItem))
      )
    ) {
      showToast(t('message:MS080004', { k: 35 }).replace('months', 'days').replace('tháng', 'ngày'), 'error')
      return false
    }
    return true
  }

  const getValuesForm = (): {
    customedFromDate: Date
    customedToDate: Date
    customedOffice: SelectOption[]
    customedTemplate: SubmissionReportConst.Extension.EXCEL | SubmissionReportConst.Extension.CSV
    customedReportType: SubmissionReportConst.Report.PM | SubmissionReportConst.Report.SM
  } => {
    const customedFromDate = watch('fromDate') as Date
    const customedToDate = watch('toDate') as Date
    const customedOffice = watch('offices') || null
    const customedTemplate = watch('template')?.value as
      | SubmissionReportConst.Extension.EXCEL
      | SubmissionReportConst.Extension.CSV

    const customedReportType = watch('reportType')?.value as
      | SubmissionReportConst.Report.PM
      | SubmissionReportConst.Report.SM

    return {
      customedFromDate,
      customedToDate,
      customedOffice,
      customedTemplate,
      customedReportType
    }
  }

  const transpileDocumentStatus = (documentStatus?: DocumentStatusType) => {
    return optionStatusNote?.find((elm) => elm.value === documentStatus)?.label
  }

  const setDefaultValuesForm = () => {
    const { customedFromDate, customedToDate } = getValuesForm() ?? {}
    const currentDate = moment(new Date()).toDate()
    const pastOneDay = !customedToDate
      ? moment().subtract(1, 'days').toDate()
      : moment(customedToDate).subtract(1, 'days').toDate()

    !customedFromDate && setValue('fromDate', pastOneDay)
    !customedToDate && setValue('toDate', currentDate)
  }

  const getTransactionTypeLabel = (transactionType: string) => {
    const transactionItem = T0Data.getValue(transactionType)
    return transactionItem
      ? i18n.language === 'en'
        ? transactionItem.transactionType
        : transactionItem.transactionTypeVn
      : ''
  }

  const getTransactionTypeLabelFromVNLabel = (transactionTypenName: string) => {
    const transactionItem = T0Data.getValueFromLabel(transactionTypenName)
    return transactionItem
      ? i18n.language === 'en'
        ? transactionItem.transactionType
        : transactionItem.transactionTypeVn
      : ''
  }

  const getViewReportAPI = (
    inputs: {
      fromDate: string
      toDate: string
      officeCode: string[]
      size?: number
      start?: number
      category?: string
    },
    callBack: ({ data, totalRecord, totalAmount }: { data: any; totalRecord: number; totalAmount: number }) => void
  ) => {
    return pipe(
      PaymentReportService.viewReport({ ...inputs }),
      ZIO.tap(({ data, start, size, total }) => {
        const totalAmount = data?.reduce((acc, curr) => {
          return (acc += curr?.amount)
        }, 0)

        const _data = data?.map((elm, index) => {
          return {
            ...elm,
            no: index,
            policyNo: elm?.policyNo || elm?.policyNumber || '-',
            policyOwner: elm.policyOwner || '-',
            payee: elm.payee || '-',
            idNumber: elm.idNumber || '-',
            agentCode: elm.agentCode || '-',
            paymentNumber: elm?.paymentNumber || '-',
            pvNumber: elm?.pvNumber || '-',
            paymentDate: elm.paymentDate || '-',
            amount: elm?.amount || 0,
            paymentOffice: elm.paymentOffice || '-',
            bankCode: elm.bankCode || '-',
            pvStatus: optionStatusNote.find((status) => elm.status === status.value)?.label || '-',
            transactionType: getTransactionTypeLabelFromVNLabel(elm.transactionType ?? '') || '-',
            createdDate: elm.createdDate || '-',
            createdBy: elm.createdBy || '-',
            verifiedDate: elm.verifiedDate || '-',
            verifiedBy: elm.verifiedBy || '-',
            authorizedDate: elm.authorizedDate || '-',
            authorizedBy: elm.authorizedBy || '-',
            status: elm.pvStatus || '-',
            note: elm.note || '-'
          }
        })

        callBack({ data: _data, totalRecord: total || data?.length, totalAmount: totalAmount || 0 })

        showGlobalLoading(false)
        setPagination((prev: Pagination) => ({
          ...prev,
          total: total ?? 0,
          pageNum: start ?? 0,
          pageSize: size ?? 10
        }))
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const getViewSubmissionReportAPI = (
    inputs: {
      fromDate: string
      toDate: string
      officeCode: string[]
      size?: number
      start?: number
      category?: string
    },
    callBack: ({ data, totalRecord, totalAmount }: { data: any; totalRecord: number; totalAmount: number }) => void
  ) => {
    return pipe(
      PaymentReportService.viewReportSubmission({ ...inputs }),
      ZIO.tap(({ data, start, size, total }) => {
        const totalAmount = data?.reduce((acc, curr) => {
          return (acc += curr?.amount as number)
        }, 0)

        const _data = data?.map((elm, index) => {
          return {
            ...elm,
            no: index,
            policyNo: elm?.policyNumber || '-',
            policyOwner: elm.policyOwner || '-',
            payee: elm.payee || '-',
            idNumber: elm.idNumber || '-',
            agentCode: elm.agentCode || '-',
            paymentNumber: elm?.pvNumber || '-',
            pvNumber: elm?.paymentNumber || '-',
            paymentDate: elm.paymentDate || '-',
            amount: elm?.amount || 0,
            paymentOffice: elm.paymentOffice || '-',
            bankCode: elm.bankCode || '-',
            pvStatus: optionStatusNote.find((status) => elm.pvStatus === status.value)?.label || '-',
            transactionType: getTransactionTypeLabel(elm.transactionType ?? '') || '-',
            createdDate: elm.createdDate || '-',
            createdBy: elm.createdBy || '-',
            verifiedDate: elm.verifiedDate || '-',
            verifiedBy: elm.verifiedBy || '-',
            authorizedDate: elm.authorizedDate || '-',
            authorizedBy: elm.authorizedBy || '-',
            status: elm.status || '-',
            note: elm.note || '-'
          }
        })

        callBack({ data: _data, totalRecord: total || data?.length, totalAmount })

        showGlobalLoading(false)
        setPagination((prev: Pagination) => ({
          ...prev,
          total: total ?? 0,
          pageNum: start && size ? start / size : 0,
          pageSize: size ?? 10
        }))
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const handleClickViewReport = async () => {
    const { customedFromDate, customedToDate, customedOffice, customedReportType } = getValuesForm() ?? {}

    setDefaultValuesForm()

    if (await validateDataFields(customedFromDate, customedToDate)) {
      const reportTypeHandler = {
        PM: () => {
          const inputs = {
            fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
            toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
            officeCode: customedOffice?.map((elm) => elm?.value) ?? [],
            size: 10,
            start: 0
          }

          return getViewReportAPI(inputs, ({ data }) => {
            if (isEmpty(data)) return showToast(t('message:MS030029'), 'error')
            return setDataSource(data)
          })
        },
        SM: () => {
          const inputs = {
            fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
            toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
            officeCode: customedOffice?.map((elm) => elm?.value) ?? [],
            size: 10,
            start: 0
          }

          return getViewSubmissionReportAPI(inputs, ({ data }) => {
            if (isEmpty(data)) return showToast(t('message:MS030029'), 'error')
            return setDataSource(data)
          })
        }
      }

      return reportTypeHandler?.[customedReportType]?.()
    }
  }

  const prepareDataToExportExcel = ({
    data,
    totalAmount,
    totalRecord
  }: {
    data: PaymentReportItemType[]
    totalRecord: number
    totalAmount: number
  }) => {
    const { customedFromDate, customedToDate, customedReportType } = getValuesForm() ?? {}
    const summary = {
      fromDate: customedFromDate,
      toDate: customedToDate,
      title: 'Báo cáo cuối ngày chi trả',
      totalRecord: totalRecord || data.length,
      totalCurrency: PaymentReportService.formatCurrency(+totalAmount) ?? '-'
    }
    const checkReportSM = customedReportType === SubmissionReportConst.Report.SM
    const checkReportPM = customedReportType === SubmissionReportConst.Report.PM
    let columns = SubmissionReportConst.displayedColumnsPaymentReport.map(({ id, name, widthPx, horizontal }) => ({
      id,
      name: t(name),
      horizontal,
      width: widthPx
    }))

    if (checkReportSM) {
      const avoidColumn = ['agentCode', 'authorizedBy']
      columns = columns.filter((item) => !avoidColumn.includes(item.id))
    }

    const rows =
      (data &&
        data
          ?.map((res, index) => ({ ...res, no: `${index + 1}` }))
          .map(
            ({
              no,
              policyNo,
              policyOwner,
              payee,
              idNumber,
              agentCode,
              paymentNumber,
              pvNumber,
              paymentDate,
              amount,
              paymentOffice,
              bankCode,
              status,
              transactionType,
              createdDate,
              createdBy,
              verifiedDate,
              verifiedBy,
              authorizedDate,
              authorizedBy,
              pvStatus,
              note
            }) =>
              checkReportPM
                ? [
                    no,
                    policyNo,
                    policyOwner,
                    payee,
                    idNumber,
                    agentCode,
                    paymentNumber,
                    pvNumber,
                    paymentDate,
                    PaymentReportService.formatCurrency(amount),
                    paymentOffice,
                    bankCode,
                    status,
                    transactionType,
                    createdDate,
                    createdBy,
                    verifiedDate,
                    verifiedBy,
                    authorizedDate,
                    authorizedBy,
                    pvStatus,
                    note
                  ]
                : [
                    no,
                    policyNo,
                    policyOwner,
                    payee,
                    idNumber,
                    // agentCode,
                    paymentNumber,
                    pvNumber,
                    paymentDate,
                    PaymentReportService.formatCurrency(amount),
                    paymentOffice,
                    bankCode,
                    status,
                    transactionType,
                    createdDate,
                    createdBy,
                    verifiedDate,
                    verifiedBy,
                    authorizedDate,
                    // authorizedBy,
                    pvStatus,
                    note
                  ]
          )) ??
      []

    return {
      summary,
      columns,
      rows
    }
  }

  const handleClickExportFile = async () => {
    const { customedFromDate, customedTemplate, customedToDate, customedOffice, customedReportType } =
      getValuesForm() ?? {}

    setDefaultValuesForm()

    if (await validateDataFields(customedFromDate, customedToDate)) {
      showGlobalLoading(true)

      const callAPIExportHandler = {
        PM: () => {
          const inputs = {
            fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
            toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
            officeCode: customedOffice?.map((elm) => elm?.value) ?? [],
            size: 10000,
            start: 0
          }

          return getViewReportAPI(inputs, ({ data, totalAmount, totalRecord }) => {
            if (isEmpty(data)) return showToast(t('message:MS030029'), 'error')

            const { rows, columns, summary } = prepareDataToExportExcel({ data, totalAmount, totalRecord }) ?? {}

            const isNeedExportCSV = data.length > 10000
            isNeedExportCSV &&
              setValue('template', {
                value: SubmissionReportConst.Extension.CSV,
                label: 'CSV'
              })

            const extension = isNeedExportCSV ? SubmissionReportConst.Extension.CSV : customedTemplate

            return ReportService.exportTemplatePaymentReport(
              { ref: 'A9', colRef: 1, rowRef: 9 },
              summary,
              columns,
              rows,
              extension
            )
          })
        },
        SM: () => {
          const inputs = {
            fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
            toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
            officeCode: customedOffice?.map((elm) => elm?.value) ?? [],
            size: 10000,
            start: 0
          }

          return getViewSubmissionReportAPI(inputs, ({ data, totalAmount, totalRecord }) => {
            if (isEmpty(data)) return showToast(t('message:MS030029'), 'error')

            const { rows, columns, summary } = prepareDataToExportExcel({ data, totalAmount, totalRecord }) ?? {}

            return ReportService.exportTemplatePaymentReport(
              { ref: 'A9', colRef: 1, rowRef: 9 },
              summary,
              columns,
              rows,
              customedTemplate
            )
          })
        }
      }

      return callAPIExportHandler?.[customedReportType]?.() ?? {}
    }
  }

  const handleChangePage = (page: number) => {
    const { customedFromDate, customedOffice, customedToDate, customedReportType } = getValuesForm() ?? {}

    const callAPIExportHandler = {
      PM: () => {
        const inputs = {
          fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
          toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
          officeCode: customedOffice?.map((elm) => elm?.value) ?? [],
          size: pageSize,
          start: page
        }

        return getViewReportAPI(inputs, ({ data }) => setDataSource(data))
      },
      SM: () => {
        const inputs = {
          fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
          toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
          officeCode: customedOffice?.map((elm) => elm?.value) ?? [],
          size: pageSize,
          start: page * pageSize
        }
        return getViewSubmissionReportAPI(inputs, ({ data }) => setDataSource(data))
      }
    }
    return callAPIExportHandler?.[customedReportType]?.()
  }

  const handleChangePerPage = (perPage: number) => {
    const { customedFromDate, customedOffice, customedToDate, customedReportType } = getValuesForm() ?? {}

    const inputs = {
      fromDate: moment(PulseOpsFormat.startOfDate(customedFromDate)).format(),
      toDate: moment(PulseOpsFormat.endOfDate(customedToDate)).format(),
      officeCode: customedOffice?.map((elm) => elm?.value) ?? [],
      size: perPage,
      start: 0
    }

    const callAPIExportHandler = {
      PM: () => {
        return getViewReportAPI(inputs, ({ data }) => setDataSource(data))
      },
      SM: () => {
        return getViewSubmissionReportAPI(inputs, ({ data }) => setDataSource(data))
      }
    }
    return callAPIExportHandler?.[customedReportType]?.()
  }

  const handleClickActions = (typeAction: actionType) => {
    const actionsHandler = {
      VIEW: () => handleClickViewReport(),

      EXPORT: () => handleClickExportFile()
    }

    return actionsHandler?.[typeAction]?.()
  }

  /**
   * Views
   */
  const renderTableHeader = () => {
    const { customedReportType } = getValuesForm() ?? {}
    if (customedReportType === SubmissionReportConst.Report.SM)
      return titleHeadersForGA.map((title, index) => {
        return (
          <TableCell align="center" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }} key={index}>
            {title}
          </TableCell>
        )
      })

    return titleHeaders.map((title, index) => {
      return (
        <TableCell align="center" style={{ minWidth: 150, fontWeight: 'bold', fontSize: 15 }} key={index}>
          {title}
        </TableCell>
      )
    })
  }

  const renderTableBody = () => {
    const { customedReportType } = getValuesForm() ?? {}
    const checkReportPM = customedReportType === SubmissionReportConst.Report.PM
    return dataSource?.map((row, index) => {
      return (
        <TableRow key={`${row.policyNo}-${index}`}>
          <TableCell align="center">{row.policyNo}</TableCell>
          <TableCell align="center">{row.policyOwner}</TableCell>
          <TableCell align="center">{row.payee}</TableCell>
          <TableCell align="center">{row.idNumber}</TableCell>
          {checkReportPM && <TableCell align="center">{row.agentCode}</TableCell>}
          <TableCell align="center">{row.paymentNumber}</TableCell>
          <TableCell align="center">{row.pvNumber}</TableCell>
          <TableCell align="center">{row.paymentDate}</TableCell>
          <TableCell align="center">{PaymentReportService.formatCurrency(row.amount)}</TableCell>
          <TableCell align="center">{row.paymentOffice}</TableCell>
          <TableCell align="center">{row.bankCode}</TableCell>
          <TableCell align="center">{row.status}</TableCell>
          <TableCell align="center">{row.transactionType}</TableCell>
          <TableCell align="center">{row.createdDate}</TableCell>
          <TableCell align="center">{row.createdBy}</TableCell>
          <TableCell align="center">{row.verifiedDate}</TableCell>
          <TableCell align="center">{row.verifiedBy}</TableCell>
          <TableCell align="center">{row.authorizedDate}</TableCell>
          {checkReportPM && <TableCell align="center">{row.authorizedBy}</TableCell>}
          <TableCell align="center">{row.pvStatus}</TableCell>
          <TableCell align="center">{row.note}</TableCell>
        </TableRow>
      )
    })
  }

  const renderTable = () => {
    if (isEmpty(dataSource)) return
    return (
      <View style={{ marginTop: 20, marginBottom: 20 }}>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow style={{ backgroundColor: '#e2e7ea' }}>{renderTableHeader()}</TableRow>
            </TableHead>

            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          page={pageNum}
          rowsPerPage={pageSize}
          count={total}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) => handleChangePerPage(+e.target.value)}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      </View>
    )
  }

  const renderActions = () => {
    return (
      <View style={[PRStyle.col_4, { flex: 1, flexDirection: 'row', alignItems: 'center' }]}>
        {actions.map((action, index) => {
          const { text, isHavePermission, type } = action
          const isButtonView = type === Action.VIEW

          return (
            <React.Fragment key={index}>
              {isHavePermission && (
                <TouchableOpacity
                  style={{ width: 118, height: 40, ...(isButtonView && { marginRight: 30 }) }}
                  onPress={() => handleClickActions(type as actionType)}
                >
                  <View style={PRStyle.primaryBtn}>
                    <Text style={{ textAlign: 'center', color: '#fff' }}>{text}</Text>
                  </View>
                </TouchableOpacity>
              )}
            </React.Fragment>
          )
        })}
      </View>
    )
  }

  const renderTotal = () => {
    if (isEmpty(dataSource)) return

    return totals.map((total, index) => {
      return (
        <View style={[PRStyle.totalRow, PRStyle.secondLine]} key={index}>
          <Text style={{ textAlign: 'left', width: 170, fontWeight: 'bold', fontSize: 15 }}>{total.title}</Text>
          <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15, color: '#ff0000' }}>
            {total?.value || '-'}
          </Text>
        </View>
      )
    })
  }

  return (
    <View style={PRStyle.pageContent}>
      <ScrollView style={[PRStyle.container]}>
        <View style={PRStyle.sectionContent}>
          <View style={PRStyle.sectionRow}>
            <View style={PRStyle.col_4}>
              <Controller
                name="fromDate"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:FromDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                  />
                )}
              />
            </View>
            <View style={PRStyle.col_4}>
              <Controller
                name="toDate"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <DatePicker
                    label={t('common:ToDate')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    errorMessage={!!value ? '' : error?.message}
                    maxDate={maxDate}
                    alwaysShow={true}
                  />
                )}
              />
            </View>
            <View style={PRStyle.col_4}>
              <Controller
                name="offices"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectSearch
                    label={`${t('ServiceInquiry:Office')}`}
                    options={officeCodes}
                    buildLabel={(select) => `${select.value} - ${select.label}`}
                    placeholder={officeGA ? '' : t('common:Select')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    multiple={true}
                    disabled={officeGA !== null}
                    showPopupIcon={officeGA === null}
                  />
                )}
              />
            </View>
          </View>

          <View style={[PRStyle.sectionRow, PRStyle.secondLine]}>
            <View style={PRStyle.col_4}>
              <Controller
                name="template"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:Template')}
                    options={SubmissionReportConst.TemplateList}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>

            <View style={PRStyle.col_4}>
              <Controller
                name="reportType"
                control={control}
                render={({ field }) => (
                  <SelectSearch
                    {...field}
                    popupIcon={<assets.ArrowDownDropdownIcon />}
                    label={t('Reports:ReportType')}
                    options={SubmissionReportConst.ReportList}
                    placeholder={t('common:Select')}
                  />
                )}
              />
            </View>
          </View>
          <View style={[PRStyle.sectionRow, PRStyle.secondLine]}>{renderActions()}</View>
        </View>

        {loading && <LinearProgress color="secondary" />}
        {renderTotal()}
        {renderTable()}
      </ScrollView>
    </View>
  )
}

const PRStyle = StyleSheet.create({
  pageContent: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%'
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15
  },
  sectionContent: {
    padding: 20,
    backgroundColor: '#fafafa',
    border: '1px solid #d3dce6',
    boxSizing: 'border-box',
    borderRadius: 8,
    marginTop: 30
  },
  sectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginRight: -15,
    marginLeft: -15
  },
  totalRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginLeft: 5
  },
  secondLine: {
    marginTop: 20
  },
  col_4: {
    width: '100%',
    maxWidth: '33.3333333333%',
    paddingRight: 15,
    paddingLeft: 15
  },
  primaryBtn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },
  cellWidth200: {
    minWidth: '200px'
  }
})
