import React, { useState } from 'react'
import { FlatList, TouchableOpacity, View, Text, StyleSheet } from 'react-native'
import {
  AppContext,
  ErrorHandling,
  GeneralInfoService,
  Permission,
  TabList,
  TASK_DETAIL_ROLES,
  useMobile,
  Tab,
  SubmissionPermissions,
  TaskType
} from '@pulseops/common'
import { FontAwesome5 } from '@expo/vector-icons'
import { StackNavigationProp } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { AgentInfoData, AgentScreen } from './AgentScreen'
import { TaskDetailStackParamList } from '../..'
import { PolicyInfoData, PolicyInfoScreen } from './PolicyScreen'
import { CustomerInfoData, CustomerScreen } from './CustomerScreen'
import { InvestmentInfoData, InvestmentScreen } from './InvestmentScreen'
import { PolicyValueInfoData, PolicyValueScreen } from './PolicyValueScreen'
import { ProductInfoData, ProductScreen } from './ProductScreen'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { IFQuotationData, IFQuotationScreen } from './IFQuotation'
import { TranData, TransactionHistoryScreen } from './TransactionHistoryScreen'
import { APS } from '../annual-policy-statement'

export const GeneralInfoScreen = (props: {
  navigation:
  | StackNavigationProp<TaskDetailStackParamList, 'TaskDetailScreen'>
  | StackNavigationProp<TaskDetailStackParamList, 'PolicyDetail'>
  policyNum: string
  roles: string[]
  isGeneral?: boolean
  officeCode?: string | null
  caterogy?: string
}) => {
  const { isMobile, isWide } = useMobile()
  const { t } = useTranslation()

  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const [subTabIndex, setSubTabIndex] = React.useState(0)
  const [policyInfo, setPolicyInfo] = useState<PolicyInfoData | null>(null)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfoData | null>(null)
  const [productInfo, setProductInfo] = useState<ProductInfoData | null>(null)
  const [pvInfo, setPVInfo] = useState<PolicyValueInfoData | null>(null)
  const [investInfo, setInvestInfo] = useState<InvestmentInfoData | null>(null)
  const [agentInfo, setAgentInfo] = useState<AgentInfoData | null>(null)
  const [IFQuotationInfo, setIFQuotationInfo] = useState<IFQuotationData | null>(null)
  const [tranHistory, setTranHistory] = useState<TranData[] | null>(null)

  const generalInfos = [
    {
      id: 0,
      title: t('Tab:PolicyInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoPolicyTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoPolicyPC,
      policyDetailPermission: SubmissionPermissions.ViewPolicyInfoPolicyDetail
    },
    {
      id: 1,
      title: t('Tab:CustomerInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoCustomerTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoCustomerPC,
      policyDetailPermission: SubmissionPermissions.ViewCustomerInfoPolicyDetail
    },
    {
      id: 2,
      title: t('Tab:ProductInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoProductTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoProductPC,
      policyDetailPermission: SubmissionPermissions.ViewProductInfoPolicyDetail
    },
    {
      id: 3,
      title: t('Tab:PolicyValueInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValuePC,
      policyDetailPermission: SubmissionPermissions.ViewPolicyValueInfoPolicyDetail
    },
    {
      id: 4,
      title: t('Tab:InvestmentInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentPC,
      policyDetailPermission: SubmissionPermissions.ViewInvestmentInfoPolicyDetail
    },
    {
      id: 5,
      title: t('Tab:AgentInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoAgentPC,
      policyDetailPermission: SubmissionPermissions.ViewAgentInfoPolicyDetail
    },
    {
      id: 6,
      title: t('Tab:TransactionHistory'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoAgentPC,
      policyDetailPermission: SubmissionPermissions.ViewAgentInfoPolicyDetail
    },

    {
      id: 7,
      title: t('Tab:APS'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoAgentTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoAgentPC,
      policyDetailPermission: SubmissionPermissions.ViewAgentInfoPolicyDetail
    }
  ]

  const generalInfosIDC = [
    {
      id: 0,
      title: t('Tab:PolicyInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoPolicyIDCTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoPolicyPC,
      policyDetailPermission: SubmissionPermissions.ViewPolicyInfoPolicyDetail
    },
    {
      id: 1,
      title: t('Tab:CustomerInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoCustomerIDCTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoCustomerPC,
      policyDetailPermission: SubmissionPermissions.ViewCustomerInfoPolicyDetail
    },
    {
      id: 2,
      title: t('Tab:ProductInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoProductIDCTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoProductPC,
      policyDetailPermission: SubmissionPermissions.ViewProductInfoPolicyDetail
    },
    {
      id: 3,
      title: t('Tab:PolicyValueInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValueIDCTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoPolicyValuePC,
      policyDetailPermission: SubmissionPermissions.ViewPolicyValueInfoPolicyDetail
    },
    {
      id: 4,
      title: t('Tab:InvestmentInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentIDCTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoInvestmentPC,
      policyDetailPermission: SubmissionPermissions.ViewInvestmentInfoPolicyDetail
    },
    {
      id: 5,
      title: t('Tab:AgentInfo'),
      permission:
        props.caterogy !== 'PC'
          ? TASK_DETAIL_ROLES.ViewGeneralInfoAgentIDCTaskDetail
          : TASK_DETAIL_ROLES.ViewGeneralInfoAgentPC,
      policyDetailPermission: SubmissionPermissions.ViewAgentInfoPolicyDetail
    }
  ]

  const getTabs = () => {
    if (props.roles.length > 0) {
      let visibleTabs: Tab[] = props.caterogy === TaskType.IDC ?
        generalInfosIDC.filter((item) =>
          props.roles.includes(props.isGeneral ? item.permission : item.policyDetailPermission)
        ) :
        generalInfos.filter((item) =>
          props.roles.includes(props.isGeneral ? item.permission : item.policyDetailPermission)
        )

      // if (!props.isGeneral && props.roles.includes(Permission.View_IFQuotation_PD)) {
      //   visibleTabs.push(
      //     {
      //       id: 8,
      //       title: t('Tab:IFQuotation')
      //     }
      //   )
      // }

      if (visibleTabs.length > 0 && visibleTabs.find((item) => item.id === subTabIndex) === undefined) {
        setSubTabIndex(visibleTabs[0].id)
      }

      return visibleTabs
    }

    return []
  }

  // const isNotIFQuotation = pipe(
  //   props.isGeneral ? ZIO.succeed(true) : GeneralInfoService.checkIFQuotationRule(props.policyNum),
  //   ErrorHandling.runDidMount()
  // )

  const accept = (roles: string) => {
    return (
      (roles === 'PLI' && !props.isGeneral && props.roles.includes(Permission.ViewPolicyInfoPolicyDetail)) ||
      (props.isGeneral &&
        (props.roles.includes(Permission.ViewGeneralInfoPolicyTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoPolicyIDCTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoPolicyPC))) ||
      (roles === 'CI' && !props.isGeneral && props.roles.includes(Permission.ViewCustomerInfoPolicyDetail)) ||
      (props.isGeneral &&
        (props.roles.includes(Permission.ViewGeneralInfoCustomerTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoCustomerIDCTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoCustomerPC))) ||
      (roles === 'PDI' && !props.isGeneral && props.roles.includes(Permission.ViewProductInfoPolicyDetail)) ||
      (props.isGeneral &&
        (props.roles.includes(Permission.ViewGeneralInfoProductTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoProductIDCTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoProductPC))) ||
      (roles === 'PVI' && !props.isGeneral && props.roles.includes(Permission.ViewPolicyValueInfoPolicyDetail)) ||
      (props.isGeneral &&
        (props.roles.includes(Permission.ViewGeneralInfoPolicyValueTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoPolicyValueIDCTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoPolicyValuePC))) ||
      (roles === 'II' && !props.isGeneral && props.roles.includes(Permission.ViewInvestmentInfoPolicyDetail)) ||
      (props.isGeneral &&
        (props.roles.includes(Permission.ViewGeneralInfoInvestmentTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoInvestmentIDCTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoInvestmentPC))) ||
      (roles === 'AI' && !props.isGeneral && props.roles.includes(Permission.ViewAgentInfoPolicyDetail)) ||
      (roles === 'TH' && !props.isGeneral && props.roles.includes(Permission.ViewAgentInfoPolicyDetail)) ||
      (roles === 'APS' && !props.isGeneral && props.roles.includes(Permission.ViewAPSPolicyDetail)) ||
      (!props.isGeneral &&
        (props.roles.includes(Permission.ViewGeneralInfoAgentTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoAgentIDCTaskDetail) ||
          props.roles.includes(Permission.ViewGeneralInfoAgentPC))) ||
      (roles === 'IFQ' && !props.isGeneral && props.roles.includes(Permission.View_IFQuotation_PD))
    )
  }

  const GeneralInfoItem = ({
    tabId,
    title,
    onNavigation
  }: {
    tabId: number
    title: string
    onNavigation: (tabId: number) => void
  }) => {
    return (
      <TouchableOpacity onPress={() => onNavigation(tabId)}>
        <View style={styles.generalInfoItem}>
          <Text style={styles.generalInfoItemText}>{title}</Text>
          <FontAwesome5 name="angle-right" size={20} color="#B0B0B0" />
        </View>
      </TouchableOpacity>
    )
  }

  const onChangePage = (tabId: number) => {
    switch (tabId) {
      case 0:
        props.navigation.navigate('PolicyScreen')
        break
      case 1:
        props.navigation.navigate('CustomerScreen')
        break
      case 2:
        props.navigation.navigate('ProductScreen')
        break
      case 3:
        props.navigation.navigate('PolicyValueScreen')
        break
      // case 6:
      //   props.navigation.navigate('TransactionHistoryScreen')
      //   break
      default:
        break
    }
  }

  const onChangeSubTab = (tabIdx: number) => {
    // if (tabIdx === 8 && !isNotIFQuotation) {
    //   showToast(t('message:MS990022'), 'error')
    // }
    setSubTabIndex(tabIdx)
  }

  return (
    <View style={styles.tabContainer}>
      {isMobile ? (
        <>
          <FlatList
            data={getTabs()}
            renderItem={({ item }) => (
              <GeneralInfoItem tabId={item.id} onNavigation={onChangePage} title={item.title} />
            )}
            keyExtractor={(item) => item.id.toString()}
          />
        </>
      ) : (
        <TabList
          gutterBottom
          isSubTab={true}
          useOriginalId={true}
          menus={getTabs()}
          tabIndex={subTabIndex}
          onChangeTab={onChangeSubTab}
        />
      )}
      <View style={{ flex: 1 }}>
        {accept('PLI') && subTabIndex === 0 && isWide && props.policyNum && (
          <PolicyInfoScreen
            id={props.policyNum}
            data={policyInfo}
            setData={setPolicyInfo}
            isGeneral={!!props.isGeneral}
          />
        )}
        {accept('CI') && subTabIndex === 1 && isWide && props.policyNum && (
          <CustomerScreen id={props.policyNum} data={customerInfo} caterogy={props.caterogy} setData={setCustomerInfo} isGeneral={props.isGeneral} />
        )}
        {accept('PDI') && subTabIndex === 2 && isWide && props.policyNum && (
          <ProductScreen id={props.policyNum} data={productInfo} setData={setProductInfo} />
        )}
        {accept('PVI') && subTabIndex === 3 && isWide && props.policyNum && (
          <PolicyValueScreen id={props.policyNum} data={pvInfo} setData={setPVInfo} />
        )}
        {accept('II') && subTabIndex === 4 && isWide && props.policyNum && (
          <InvestmentScreen id={props.policyNum} data={investInfo} setData={setInvestInfo} />
        )}
        {accept('AI') && subTabIndex === 5 && isWide && props.policyNum && (
          <AgentScreen id={props.policyNum} data={agentInfo} setData={setAgentInfo} />
        )}
        {accept('TH') && subTabIndex === 6 && isWide && props.policyNum && (
          <TransactionHistoryScreen id={props.policyNum} data={tranHistory} setData={setTranHistory} />
        )}
        {accept('APS') && subTabIndex === 7 && isWide && props.policyNum && <APS />}
        {/* {accept('IFQ') && isNotIFQuotation && subTabIndex === 8 && isWide && props.policyNum && props.caterogy !== 'IDC' && (
          <IFQuotationScreen
            id={props.policyNum}
            data={IFQuotationInfo}
            setData={setIFQuotationInfo}
            editPermissionFlag={props.roles.includes(Permission.Edit_IFQuotation_PD)}
            officeCode={props.officeCode}
          />
        )} */}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginHorizontal: 15,
    justifyContent: 'center'
  },
  // Tab ++
  tabContainer: {
    marginHorizontal: 15,
    marginBottom: 15,
    flex: 1
  },
  // Tab --
  bold: {
    fontWeight: 'bold'
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: '800'
  },
  // General Info Item
  generalInfoItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 55,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 22,
    marginBottom: 12
  },
  generalInfoItemText: {
    fontSize: 16,
    lineHeight: 28
  },
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  },
  sectionStatus: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16
  },
  sectionStatusTag: {
    backgroundColor: '#1EA5FC',
    borderRadius: 4,
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginRight: 16
  },
  sectionStatusTagText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold'
  },
  dangerText: {
    color: '#ED1B2E',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  strongText: {
    fontSize: 15,
    lineHeight: 14,
    fontWeight: '600',
    marginLeft: 10
  },
  sectionInfoContentRow: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  sectionInfoContentCol: {
    width: '33.33333333333%',
    marginBottom: 32
  },
  sectionInfoContentLabel: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  sectionInfoContentValue: {
    color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  footer: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    height: 70,
    shadowOffset: {
      width: 0,
      height: -5
    },
    shadowColor: '#e6e6e6',
    shadowOpacity: 15,
    shadowRadius: 24,
    alignItems: 'center'
  },
  textButton: { textAlign: 'center', color: '#ED1B2E', fontWeight: 'bold' },
  button: {
    marginLeft: 20,
    marginVertical: 20,
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#ED1B2E',
    minWidth: 121,
    height: 35,
    paddingHorizontal: 30
  }
})
